import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation, Link } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import classNames from 'classnames';
import Icon from '../molecules/svg_icon';
import { useSnackbar } from 'notistack';
import texts from '../utils/texts';

const useStyles = makeStyles((theme) => ({
  link: {
    height: 48,
    minHeight: 48,
    padding: '0 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    borderRadius: 15,
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      backgroundColor: 'rgb(138,233,235, 0.2)',
      color: 'inherit',
    },
    '&:visited': {
      color: 'inherit',
    },
    '&:active': {
      color: 'inherit',
    },
    '&:not(:last-child)': {
      marginBottom: 12,
    },
    [theme.breakpoints.down('md')]: {
      width: 48,
      padding: '0 16px',
    },
  },
  subContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  activeRoute: {
    backgroundColor: 'rgba(50, 99, 226, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(50, 99, 226, 0.1)',
    },
  },
  text: {
    marginLeft: 23,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  activeText: {
    color: theme.palette.primary.main,
  },
}));

const useTooltipStyle = makeStyles((theme) => ({
  tooltip: {
    fontSize: 14,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const SidebarButton = ({ route, text, disabled = false }) => {
  const theme = useTheme();
  const classes = useStyles();
  const tooltipClasses = useTooltipStyle();
  let { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const isActive = pathname.startsWith(`/${route}`);

  const onClick = (e) => {
    if (disabled) {
      enqueueSnackbar(texts.comingSoon);
    }
  };

  const iconName = route === 'unbilled-appointments' ? 'credit_card' : route.split('-').join('_');
  return (
    <Tooltip title={text} placement="right" classes={tooltipClasses}>
      <Link
        to={!disabled ? `/${route}` : '#'}
        href={!disabled ? route : '#'}
        disabled={disabled}
        onClick={onClick}
        data-testid={route}
        className={classNames(classes.link, isActive && classes.activeRoute)}>
        <div className={classes.subContainer}>
          <Icon name={iconName} fill={isActive ? theme.palette.primary.main : '#333333'} fillOpacity={isActive ? '1' : '0.4'} />
          <span className={classNames(classes.text, isActive && classes.activeText)}>{text}</span>
        </div>
      </Link>
    </Tooltip>
  );
};

SidebarButton.propTypes = {
  route: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default SidebarButton;
