import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';

import AppIcon from './app_icon';
import texts from '../utils/texts';
import ChatIcon from './chat_icon';
import { routeHasBar } from '../components/router';
import { SystemState } from '../types/system_state';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px 25px',
  },
  contentContainer: {
    width: 150,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const AppTopBar: React.FC<{}> = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state: SystemState) => state.user);

  const shouldShowTopBar = () => {
    return routeHasBar(location) && user;
  };

  const onClick = () => {
    enqueueSnackbar(texts.comingSoon);
  };

  const chatClickedHandler = () => {
    history.push('/chat');
  };

  if (!shouldShowTopBar()) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <AppIcon name="search" onClick={onClick} />
        <AppIcon name="alarm" onClick={onClick} />
        <AppIcon name="chat" onClick={chatClickedHandler}>
          <ChatIcon />
        </AppIcon>
      </div>
    </div>
  );
};

export default AppTopBar;
