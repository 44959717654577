import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import Icon from './svg_icon';
import Badge from '../gi_styles/badge';
import { useReduxState } from '../hooks/use_redux_state';
import { Patient } from '../types/patient';

const useStyles = makeStyles({
  badge: {
    width: 14,
    height: 14,
    position: 'absolute',
    top: 6,
    right: 6,
    border: '1px solid red',
    borderRadius: '50%',
    backgroundColor: '#F8F8F8',
  },
  badgeText: {
    color: 'red',
    fontSize: 7,
  },
});

const ChatIcon: React.FC<{}> = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [user] = useReduxState({ key: 'user' });
  const [chatSelectedUser] = useReduxState({ key: 'chatSelectedUser' });
  const isChatPage = window.location.href.includes('chat');

  const getUnreadMessagesCount = () => {
    const notSelectedPatients = user.patients?.filter((p: Patient) => p.userId !== chatSelectedUser?.userId || !isChatPage);
    return notSelectedPatients.map((p: Patient) => p.unreadMessagesCount).reduce((n1: number, n2: number) => n1 + n2, 0);
  };

  const unreadMessagesCount = getUnreadMessagesCount();
  const hasUnread = unreadMessagesCount > 0;
  return (
    <>
      {hasUnread && (
        <Badge className={classes.badge}>
          <div className={classes.badgeText}>{unreadMessagesCount}</div>
        </Badge>
      )}
      <Icon name="chat" fill={theme.palette.primary.main} />
    </>
  );
};

export default ChatIcon;
