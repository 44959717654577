import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  smallButton: {
    fontSize: 16,
    fontWeight: 700,
  },
});

interface Props {
  className?: string;
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}

const SmallButton: React.ComponentType<Props> = React.forwardRef(
  ({ className, children }: Props, ref?: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();

    return (
      <div ref={ref} className={classNames(classes.smallButton, className)}>
        {children}
      </div>
    );
  },
);

export default SmallButton;
