import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  body: {
    fontSize: 16,
  },
});

interface Props {
  className?: string;
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}

const Body: React.ComponentType<Props> = React.forwardRef(({ className, children }: Props, ref?: React.Ref<HTMLDivElement>) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classNames(classes.body, className)}>
      {children}
    </div>
  );
});

export default Body;
