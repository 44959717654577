import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../actions/auth';

export const useLogoutListener = () => {
  const dispatch = useDispatch();

  const storageChange = useCallback(
    (event) => {
      if (event.key === 'logout-event') {
        dispatch(logout({ raiseLogoutEvent: false }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('storage', storageChange, false);
    return () => {
      window.removeEventListener('storage', storageChange, false);
    };
  }, [storageChange]);
};
