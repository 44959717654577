export const TAMAR = '+17328333673';
export const DAVID_PROVIDER = '+972504713222';
export const DAVID_PATIENT = '+972527605856';
export const CD_PROVIDER = '+19299005422';
export const CD_PATIENT = '+17182003618';
export const ELIOR_PROVIDER = '+972544944098';
export const ELIOR_PATIENT = '+972544944090';
export const ELIOR_DEMO_PROVIDER = '+19172111115';
export const ELIOR_DEMO_PATIENT = '+19173111115';
export const DAVID_DEMO_PROVIDER = '+19172111111';
export const DAVID_DEMO_PATIENT = '+19173111111';
export const APPLE_PROVIDER = '+19177011111';
export const APPLE_PATIENT = '+19117701111';
export const CD_WIFE = '+16469533401';

export const TAMAR_PATIENTS_ADMINS = [ELIOR_PROVIDER, DAVID_PROVIDER, TAMAR, CD_PROVIDER];
