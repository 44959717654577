import axios from 'axios';

interface PermissionParams {
  username: string;
  groupName: string;
  action: string;
}

export const getUsersPermissions = async () => {
  const { data } = await axios.get(`/admin/groups/`);
  return { type: 'usersPermissions', payload: data };
};

export const setUserPermission = async (params: PermissionParams) => {
  await axios.post(`/admin/groups/`, params);
  return await getUsersPermissions();
};
