import { useEffect } from 'react';
import { OTStreams, OTSubscriber } from 'opentok-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import useVideoDimensionsByInterval from '../hooks/use_video_dimensions_by_interval';
import Loading from '../organism/loading';

const useClasses = makeStyles({
  container: ({ width, height }) => ({
    width,
    height,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  }),
  videoCancelled: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
  },
});

const VideoSubscriber = () => {
  const isVideoCallJoined = useSelector((s) => s.isVideoCallJoined);
  const { width, height } = useVideoDimensionsByInterval();
  const isVideoCallActive = useSelector((s) => s.isVideoCallActive);
  const classes = useClasses({ width, height });

  useEffect(() => {
    const handler = setInterval(() => {
      document.querySelectorAll('.OT_edge-bar-item').forEach((barItem) => {
        barItem.style.display = 'none';
      });
      document.querySelectorAll('.OT_audio-level-meter').forEach((barItem) => {
        barItem.style.display = 'none';
      });
    }, 300);
    return () => clearInterval(handler);
  });

  useEffect(() => {
    const setDimensions = () => {
      document.querySelectorAll('.OTSubscriberContainer').forEach((subscriber) => {
        subscriber.style.width = `${width}px`;
        subscriber.style.height = `${height}px`;
      });
    };
    setDimensions();
    const handler = setInterval(setDimensions, 1000);
    return () => clearInterval(handler);
  }, [width, height]);

  const properties = {
    subscribeToAudio: isVideoCallActive,
    subscribeToVideo: isVideoCallActive,
  };

  return (
    <div className={classes.container}>
      <Loading inAction={!isVideoCallJoined} />
      <OTStreams>
        <OTSubscriber properties={properties} />
      </OTStreams>
    </div>
  );
};

export default VideoSubscriber;
