import axios from 'axios';
import humps from 'humps';
import texts from './texts';
import { captureMessage } from '@sentry/browser';
import { snackbarRef } from '../utils/snackbar';

import { store } from '../redux/store';
import { logout } from '../actions/auth';

export const baseUrl = window.env.REACT_APP_API_URL;

const isRefresh = (path) => {
  return path.startsWith('/auth/token/refresh') || path.startsWith('auth/token/refresh');
};

const handleDefaultServerUnauthorizedError = (response, pathname) => {
  if (response.data?.type === 'bad_password') {
    store.dispatch({ type: 'accessToken', payload: '' });
    snackbarRef.current.enqueueSnackbar(texts.invalidCredentials, { variant: 'error', autoHideDuration: 5000 });
  }
  if (isRefresh(pathname) && response.data?.type === 'bad_token') {
    store.dispatch(logout());
  }
  if (!pathname.startsWith('/auth/') || !pathname.startsWith('auth/')) {
    store.dispatch({ type: 'accessToken', payload: '' });
  }
};

const handleErrors = (err) => {
  if (err?.message === 'Network Error') {
    snackbarRef.current.enqueueSnackbar(texts.pleaseCheckYourInternetConnection, { variant: 'error', autoHideDuration: 5000 });
    return true;
  }
  const { response } = err;
  const isBaseURL = err.config.baseURL === axios.defaults.baseURL;
  if (!response || response.status >= 500) {
    captureMessage(`Failed on ${response?.request._url}, status: ${response?.status}`);
    snackbarRef.current.enqueueSnackbar(texts.generalError, { variant: 'error', autoHideDuration: 5000 });
    return true;
  }
  if (response.status === 401 && isBaseURL) {
    const pathname = err.config.url;
    handleDefaultServerUnauthorizedError(response, pathname);
    return true;
  }
  return false;
};

export const initAxios = () => {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.response.use(
    (response) => {
      if (response.data) {
        response.data = humps.camelizeKeys(response.data);
      }
      return response;
    },
    (error) => {
      error.isTreated = handleErrors(error);
      return Promise.reject(error);
    },
  );

  axios.interceptors.request.use((config) => {
    if (config.data) {
      config.data = humps.decamelizeKeys(config.data);
    }
    const { accessToken } = store.getState();
    if (accessToken && !isRefresh(config.url)) {
      config.headers.common.Authorization = `Token ${accessToken}`;
    }
    return config;
  });
};
