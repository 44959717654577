import { useSelector } from 'react-redux';

export const VIDEO_HEIGHT = 340;
export const VIDEO_WIDTH = 420;
const useVideoDimensionsByInterval = ({ isFromMobile = true } = {}) => {
  const videoSize = useSelector((s) => s.videoSize);
  const anotherWindowDimensions = useSelector((s) => s.anotherWindowDimensions);
  const isMaximize = videoSize === 'maximize';
  const minimizeWidth = 300;
  const minimizeHeight = 260;
  const defaultWidth = isMaximize ? VIDEO_WIDTH : minimizeWidth;
  const defaultHeight = isMaximize ? VIDEO_HEIGHT : minimizeHeight;
  let width = defaultWidth;
  let height = defaultHeight;

  if (!isFromMobile || !anotherWindowDimensions) {
    return { height, width };
  }

  const yourRatio = width / height;
  const anotherRatio = anotherWindowDimensions.width / anotherWindowDimensions.height;
  if (yourRatio < anotherRatio) {
    height = width / anotherRatio;
  } else {
    width = height * anotherRatio;
  }

  width = Math.round(width);
  height = Math.round(height);

  return { height, width };
};

export default useVideoDimensionsByInterval;
