import { Suspense } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import Loading from './loading';
import Router from '../components/router';
import OpentokVideo from './opentok_video';
import AppSidebar from './app_sidebar';
import ErrorBoundary from '../utils/error_boundary';
import AppTopBar from '../molecules/app_top_bar';
import CircularProgressbar from './circular_progress_bar';

const useClasses = makeStyles({
  container: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    backgroundColor: '#F8F8F8',
    overflow: 'hidden',
  },
  contentContainer: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
});

const MainApp = () => {
  const classes = useClasses();

  return (
    <Box display="flex" className={classes.container}>
      <AppSidebar />
      <Box
        className={classNames(classes.contentContainer, 'app-content-container')}
        boxShadow="inset 0 0 5px rgba(0, 0, 0, 0.15)">
        <ErrorBoundary>
          <OpentokVideo />
        </ErrorBoundary>
        <AppTopBar />
        <Suspense fallback={<Loading />}>
          <CircularProgressbar />
          <Router />
        </Suspense>
      </Box>
    </Box>
  );
};

export default MainApp;
