import { ComponentType, lazy, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Location } from 'history';

import ErrorBoundary, { isChunkError } from '../utils/error_boundary';
import ProtectedRoute from './protected_route';

export const ROUTES_WITHOUT_BARS = [
  'admin',
  'tfa',
  'register/terms-and-conditions',
  'forgot-password',
  'reset-password',
  'contractor-agreement',
  'onboarding/profile-info',
  'onboarding/expertise',
  'onboarding/business-info',
  'onboarding/insurance',
  'onboarding/end',
  'onboarding/profile',
  'onboarding/sync-calendar',
  'onboarding/office-hours',
];
export const routeHasBar = (location: Location) => {
  return (
    !ROUTES_WITHOUT_BARS.includes(location.pathname.substring(1)) &&
    !location.pathname.includes('auth/') &&
    !location.pathname.includes('reenter/')
  );
};

const importRoute = (importPathFunction: any): ComponentType => {
  const Loadable = lazy(importPathFunction);
  return (props: any) => (
    <ErrorBoundary showError={false}>
      <Loadable {...props} />
    </ErrorBoundary>
  );
};

const Auth = importRoute(() => import('../pages/auth'));
const Tfa = importRoute(() => import('../pages/tfa'));
const Profile = importRoute(() => import('../pages/profile'));
const Calendar = importRoute(() => import('../pages/calendar'));
const Patients = importRoute(() => import('../pages/patients'));
const Patient = importRoute(() => import('../pages/patient'));
const Admin = importRoute(() => import('../pages/admin'));
const RegisterTerms = importRoute(() => import('../pages/register_terms'));
const ForgotPassword = importRoute(() => import('../pages/forgot_password'));
const ResetPassword = importRoute(() => import('../pages/reset_password'));
const Appointment = importRoute(() => import('../pages/appointment'));
const UnbilledAppointment = importRoute(() => import('../pages/unbilled_appointments'));
const MicrosoftLoginCode = importRoute(() => import('./microsoft_login_code'));
const GoogleLoginCode = importRoute(() => import('./google_login_code'));
const OnBoardingProfileInfo = importRoute(() => import('../pages/onboarding_profile_info'));
const OnBoardingExpertises = importRoute(() => import('../pages/onboarding_expertises'));
const Dashboard = importRoute(() => import('../pages/dashboard'));
const OnBoardingBusinessInfo = importRoute(() => import('../pages/onboarding_business_info'));
const OnBoardingInsuranceDetails = importRoute(() => import('../pages/onboarding_insurance_details'));
const OnboardingEndPage = importRoute(() => import('../pages/onboarding_end_page'));
const ContractorAgreement = importRoute(() => import('../pages/contractor_agreement'));
const Interviews = importRoute(() => import('../pages/interviews'));
const OnboardingSetImages = importRoute(() => import('../pages/onboarding_images'));
const OnboardingSyncCalendar = importRoute(() => import('../pages/onboarding_sync_calendar'));
const OnboardingWorkHours = importRoute(() => import('../pages/onboarding_work_hours'));
const Appointments = importRoute(() => import('../pages/appointments'));
const Chat = importRoute(() => import('../pages/chat'));
const PatientsInsuranceApproval = importRoute(() => import('../pages/patients_insurance_approval'));
const InsuranceClaimApproval = importRoute(() => import('../pages/insurance_claim_approval'));
const TamarPatients = importRoute(() => import('../pages/tamar_patients'));
const Library = importRoute(() => import('../pages/library'));
const AdminReports = importRoute(() => import('../pages/admin_reports'));
const Reports = importRoute(() => import('../pages/reports'));
const DefaultRoute = importRoute(() => import('./default_route'));

const Router = () => {
  useEffect(() => {
    const onChunkError = (err) => {
      if (isChunkError(err)) {
        window.location.reload();
      }
    };
    window.addEventListener('error', onChunkError);
    return () => window.removeEventListener('error', onChunkError);
  }, []);

  return (
    <Switch>
      <Route exact path="/auth/:tab" component={Auth} />
      <Route exact path="/reenter/:tab" component={Auth} />
      <Route exact path="/register/terms-and-conditions" component={RegisterTerms} />
      <Route exact path="/contractor-agreement" component={ContractorAgreement} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/tfa" component={Tfa} />
      <ProtectedRoute exact path="/microsoft-auth" component={MicrosoftLoginCode} />
      <ProtectedRoute exact path="/google-auth" component={GoogleLoginCode} />
      <ProtectedRoute exact path="/interviews" component={Interviews} />
      <ProtectedRoute exact path="/onboarding/profile-info" component={OnBoardingProfileInfo} />
      <ProtectedRoute exact path="/onboarding/expertise" component={OnBoardingExpertises} />
      <ProtectedRoute exact path="/onboarding/business-info" component={OnBoardingBusinessInfo} />
      <ProtectedRoute exact path="/onboarding/insurance" component={OnBoardingInsuranceDetails} />
      <ProtectedRoute exact path="/onboarding/end" component={OnboardingEndPage} />
      <ProtectedRoute exact path="/onboarding/profile" component={OnboardingSetImages} />
      <ProtectedRoute exact path="/onboarding/sync-calendar" component={OnboardingSyncCalendar} />
      <ProtectedRoute exact path="/onboarding/office-hours" component={OnboardingWorkHours} />
      <ProtectedRoute exact path="/profile" component={Profile} />
      <ProtectedRoute exact path="/appointments" component={Appointments} />
      <ProtectedRoute exact path="/patients" component={Patients} />
      <ProtectedRoute exact path="/patients/:patientId/:tab?" component={Patient} />
      <ProtectedRoute exact path="/admin" component={Admin} />
      <ProtectedRoute exact path="/appointments/:appointmentId/:tab?" component={Appointment} />
      <ProtectedRoute exact path="/unbilled-appointments" component={UnbilledAppointment} />
      <ProtectedRoute exact path="/dashboard" component={Dashboard} />
      <ProtectedRoute exact path="/calendar" component={Calendar} />
      <ProtectedRoute exact path="/chat" component={Chat} />
      <ProtectedRoute exact path="/insurance-patient-approval" component={PatientsInsuranceApproval} />
      <ProtectedRoute exact path="/insurance-claim-approval" component={InsuranceClaimApproval} />
      <ProtectedRoute exact path="/tamar-patients" component={TamarPatients} />
      <ProtectedRoute exact path="/library" component={Library} />
      <ProtectedRoute exact path="/admin-reports/:tab?" component={AdminReports} />
      <ProtectedRoute exact path="/Reports/:tab?" component={Reports} />
      <ProtectedRoute exact path="*" component={DefaultRoute} />
    </Switch>
  );
};

export default Router;
