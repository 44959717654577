import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  backDrop: {
    zIndex: -1,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});

interface Props {
  hide: () => void;
}

const Backdrop: React.FC<Props> = ({ hide }) => {
  const classes = useStyles();
  return <div className={classes.backDrop} onClick={hide}></div>;
};

export default Backdrop;
