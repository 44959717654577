import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { refreshUser } from '../actions/auth';

import { SystemState } from '../types/system_state';

export const useUser = () => {
  const userId = useSelector((state: SystemState) => state.user?.userId);
  const accessToken = useSelector((state: SystemState) => state.accessToken);
  const accessTokenExpiredAt = useSelector((state: SystemState) => state.accessTokenExpiredAt);
  const dispatch = useDispatch();

  useEffect(() => {
    let handler: any;
    if (userId && accessToken && accessTokenExpiredAt) {
      handler = setInterval(() => dispatch(refreshUser()), 1000 * 120);
    } else {
      clearInterval(handler);
    }
    return () => {
      clearInterval(handler);
    };
  }, [userId, accessToken, accessTokenExpiredAt, dispatch]);

  useEffect(() => {
    if (userId && accessToken) {
      dispatch(refreshUser());
    }
  }, [accessToken, dispatch, userId]);
};
