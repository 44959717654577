import dayjs from 'dayjs';
import _ from 'lodash';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc'; // eslint-disable-line

import texts from './texts';
import { Appointment } from '../types/appointment';

dayjs.extend(isBetween);

const mapAppointment = (appointment: Appointment): Appointment => {
  return {
    ...appointment,
    appointmentAtUtc: dayjs.utc(appointment.appointmentAtUtc),
    createdAt: dayjs(appointment.createdAt),
    updatedAt: dayjs(appointment.updatedAt),
    billedAt: appointment.billedAt ? dayjs(appointment.billedAt) : null,
  };
};

export const hasAppointmentNow = (appointment: Appointment) => {
  appointment = mapAppointment(appointment);
  const appointmentEndedAt = appointment.appointmentAtUtc.clone().add(appointment.durationInMinutes, 'minute');
  return dayjs().isBetween(appointment.appointmentAtUtc, appointmentEndedAt);
};

export const getNearestAppointment = (appointments: Appointment[]) => {
  const futureAppointments = appointments?.filter(
    (a) =>
      dayjs.utc().isBefore(a.appointmentAtUtc.clone().add(a.durationInMinutes, 'minute')) &&
      ['done', 'not-started'].includes(a.status),
  );
  const sortedArray = _.orderBy(futureAppointments, ['appointmentAtUtc'], ['asc']);
  return sortedArray[0];
};

export const mapAppointments = (appointments: Appointment[]) => {
  appointments = appointments?.map((appointment) => mapAppointment(appointment));
  return _.sortBy(appointments, [(a) => a.appointmentAtUtc.unix()]);
};

export const serializeAppointments = (appointments: Appointment[]) => {
  return appointments?.map((a) => ({
    ...a,
    appointmentAtUtc: a.appointmentAtUtc.toISOString?.(),
    createdAt: a.createdAt.toISOString?.(),
    updatedAt: a.updatedAt.toISOString?.(),
    billedAt: a.billedAt?.toISOString?.(),
  }));
};

const getAppointmentDate = (dates: Appointment[]) => {
  const sortedArray = _.orderBy(dates, ['asc']);
  return sortedArray[0] ? sortedArray[0].appointmentAtUtc.local().format('ddd, MMM DD YYYY') : `${'\xa0'.repeat(12)}-`;
};

export const getLastAppointmentDate = (appointments: Appointment[]) => {
  const dates = appointments?.filter((a) => a.appointmentAtUtc.isBefore(dayjs.utc()) && a.status === 'done');
  return getAppointmentDate(dates);
};

export const getNextAppointmentDate = (appointments: Appointment[]) => {
  const dates = appointments?.filter(
    (a) => a.appointmentAtUtc.isAfter(dayjs.utc()) && ['done', 'not-started'].includes(a.status),
  );
  return getAppointmentDate(dates);
};

export const isAppointmentEnded = (appointment: Appointment) => {
  if (!appointment) {
    return false;
  }
  const timeToAdd = appointment?.durationInMinutes + 15;
  const appointmentEndAt = appointment?.appointmentAtUtc.clone().add(timeToAdd, 'minute');
  return appointmentEndAt?.diff(dayjs.utc(), 'second') <= 0 || ['done', 'missed'].includes(appointment.status);
};

export const getDurationTimeText = (appointment: Appointment) => {
  const hours = Math.floor(appointment.durationInMinutes / 60);
  let minutes: string | number = appointment.durationInMinutes % 60;
  minutes = minutes > 9 ? minutes : `0${minutes}`;
  return `0${hours}:${minutes}h`;
};

export const canJoin = (appointment: Appointment) => {
  if (!appointment) {
    return false;
  }
  const { appointmentAtUtc } = appointment;
  const canJoinStart = appointmentAtUtc.clone().subtract(30, 'minute');
  const canJoinEnd = appointmentAtUtc.clone().add(appointment.durationInMinutes + 1, 'minute');
  return dayjs.utc().isBetween(canJoinStart, canJoinEnd);
};

export const getAppointmentTypeName = (appointment: Appointment) => {
  switch (appointment.type) {
    case 'initial':
      return texts.initial;
    case 'second':
      return texts.secondFollowUp;
    case 'general':
      return texts.followUp;
    default:
      return appointment.type;
  }
};
