import { useCallback, useEffect, useRef } from 'react';
import { useReduxState } from './use_redux_state';

import usePermission from './use_permission';
import { isSameDevice } from '../utils/devices';

declare const InstallTrigger: any;
declare const document: any;

const getIsSupportDeviceChangeEvent = () => {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') !== -1;
  const isFirefox = typeof InstallTrigger !== 'undefined';
  return isEdge || isEdgeChromium || isChrome || isFirefox;
};

const getDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices;
};

interface Props {
  intervalDelay: number;
}

export const useMediaSourceOptions = ({ intervalDelay }: Props) => {
  const cameraPermission = usePermission('camera');
  const microphonePermission = usePermission('microphone');
  const [availableDevices, setAvailableDevices] = useReduxState({ key: 'availableDevices' });
  const [selectedCamera, setSelectedCamera] = useReduxState({ key: 'selectedCamera' });
  const [selectedMicrophone, setSelectedMicrophone] = useReduxState({ key: 'selectedMicrophone' });
  const isSupportDeviceChangeEvent = getIsSupportDeviceChangeEvent();
  const isFirst = useRef(true);
  const prevAvailableDevices = useRef(availableDevices);

  const setDevices = useCallback(async () => {
    const newAvailableDevices = await getDevices();
    const isSameLength = prevAvailableDevices.current?.length === newAvailableDevices?.length;
    const isSame =
      isSameLength &&
      prevAvailableDevices.current?.every((device: any, index: number) => isSameDevice(device, newAvailableDevices[index]));
    if (isFirst.current || !isSame) {
      setAvailableDevices(newAvailableDevices);
    }

    const triggerSafariPermissionRequest = () => {
      if (!selectedCamera) {
        setSelectedCamera(newAvailableDevices.find((device) => device.kind === 'videoinput'));
      }
      if (!selectedMicrophone) {
        setSelectedMicrophone(newAvailableDevices.find((device) => device.kind === 'audioinput'));
      }
    };

    if (window.safari !== undefined) {
      triggerSafariPermissionRequest();
    }
  }, [selectedCamera, selectedMicrophone, setAvailableDevices, setSelectedCamera, setSelectedMicrophone]);

  useEffect(() => {
    setDevices();
  }, [setDevices, cameraPermission, microphonePermission]);

  useEffect(() => {
    const askPermission = () => navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    if (cameraPermission === 'prompt' || microphonePermission === 'prompt') {
      askPermission();
    }
  }, [cameraPermission, microphonePermission]);

  useEffect(() => {
    if (isSupportDeviceChangeEvent) {
      navigator.mediaDevices.addEventListener('devicechange', setDevices);
    }
    return () => {
      if (isSupportDeviceChangeEvent) {
        navigator.mediaDevices.removeEventListener('devicechange', setDevices, true);
      }
    };
  }, [isSupportDeviceChangeEvent, setDevices]);

  useEffect(() => {
    let interval: any = 0;
    if (!isSupportDeviceChangeEvent) {
      interval = setInterval(async () => {
        await setDevices();
      }, intervalDelay);
    }
    return () => {
      if (!isSupportDeviceChangeEvent) {
        clearInterval(interval);
      }
    };
  }, [intervalDelay, isSupportDeviceChangeEvent, setDevices]);

  isFirst.current = false;
  prevAvailableDevices.current = availableDevices;
};

export default useMediaSourceOptions;
