import { Component } from 'react';
import PropTypes from 'prop-types';

import { captureException } from '@sentry/browser';
import texts from './texts';

export const isChunkError = (err) => /Loading chunk [\d]+ failed/.test(err.message);

class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(err) {
    this.setState({ hasError: true });
    console.error(err);
    if (!isChunkError(err)) {
      captureException(err);
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.showError) {
        return <span>{texts.generalError}</span>;
      }
      return null;
    }
    if (this.props.children) {
      return this.props.children;
    }
    return null;
  }
}

ErrorBoundary.propTypes = {
  showError: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

ErrorBoundary.defaultProps = {
  children: null,
  showError: true,
};

export default ErrorBoundary;
