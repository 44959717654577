import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SystemState } from '../types/system_state';

const ProtectedRoute = ({ ...props }) => {
  const user = useSelector((state: SystemState) => state.user);
  const accessToken = useSelector((state: SystemState) => state.accessToken);

  if (user && !accessToken) {
    return <Redirect {...props} to={`/reenter/sign-in?continueWith=${window.location.pathname}`} />;
  }

  if (!user) {
    return <Redirect {...props} to="/auth/sign-up" />;
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
