import React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  container: {
    width: '100%',
    minWidth: 150,
    minHeight: 56,
    boxShadow: 'none',
  },
  text: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none',
  },
});

interface Props extends ButtonProps {
  text?: string;
  className?: string;
  textClassName?: string;
}

const Button: React.FC<Props> = React.forwardRef(({ text, children, className, textClassName, ...props }, ref) => {
  const classes = useStyles();
  return (
    <MuiButton ref={ref} className={classNames(classes.container, className)} variant="contained" color="primary" {...props}>
      {text && <div className={classNames(classes.text, textClassName)}>{text}</div>}
      {children}
    </MuiButton>
  );
});

export default Button;
