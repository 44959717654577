import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OpentokClient from '@opentok/client'; // eslint-disable-line no-unused-vars
import { OTSession, preloadScript } from 'opentok-react';
import Draggable from 'react-draggable';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouteMatch } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import VideoPanel from '../molecules/video_panel';
import VideoSubscriber from '../molecules/video_subscriber';
import VideoPublisher from '../molecules/video_publisher';
import Axios from 'axios';
import useVideoDimensionsByInterval from '../hooks/use_video_dimensions_by_interval';
import BackToAppointmentLink from '../molecules/back_to_appointment_link';
import { useReduxState } from '../hooks/use_redux_state';
import texts from '../utils/texts';

const VIDEO_TOP = 268;
const useClasses = makeStyles({
  container: ({ width, height, videoScroll }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: 'black',
    position: 'fixed',
    right: 40,
    top: VIDEO_TOP - videoScroll <= 0 ? 0 : VIDEO_TOP - videoScroll,
    width,
    height,
    '& > div': {
      width: '100%',
    },
  }),
  subscriberNoCamera: {
    position: 'fixed',
    border: '1px solid rgba(255,0,0,0.2)',
    padding: 15,
    color: 'red',
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 15,
  },
  paused: {
    alignSelf: 'center',
    zIndex: 2,
    fontSize: 30,
    fontWeight: 700,
    color: 'white',
    marginTop: 130,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

const OpentokVideo = () => {
  const isAppointmentPage = !!useRouteMatch('/appointments/:appointmentId/:tab?');
  const appointmentId = useSelector((s) => s.currentAppointmentId);
  const isVideoCallJoined = useSelector((s) => s.isVideoCallJoined);
  const publisherHasVideoError = useSelector((s) => s.publisherHasVideoError);
  const isVideoCallActive = useSelector((s) => s.isVideoCallActive);
  const videoScroll = useSelector((s) => s.videoScroll);
  const dispatch = useDispatch();
  const [token, setToken] = useState();
  const [sessionId, setSessionId] = useState();
  const [videoXPosition, setVideoXPosition] = useState(0);
  const [videoYPosition, setVideoYPosition] = useState(0);
  const containerRef = useRef();
  const { width, height } = useVideoDimensionsByInterval({ isFromMobile: false });
  const isMaximize = isAppointmentPage && videoScroll < VIDEO_TOP + VIDEO_TOP * 0.1;
  const classes = useClasses({ width, height, videoScroll });
  const [anotherWindowDimensions, setAnotherWindowDimensions] = useReduxState({ key: 'anotherWindowDimensions' });

  const getToken = useCallback(async () => {
    if (appointmentId) {
      const {
        data: { token, sessionId },
      } = await Axios.get(`appointments/opentok/${appointmentId}/`);
      setToken(token);
      setSessionId(sessionId);
    }
  }, [appointmentId, setToken, setSessionId]);

  useEffect(() => {
    getToken();
  }, [appointmentId, dispatch, getToken]);

  useEffect(() => {
    if (isMaximize) {
      dispatch({ type: 'videoSize', payload: 'maximize' });
      if (containerRef.current?.style) {
        containerRef.current.style.transform = null;
      }
      setVideoXPosition(0);
      setVideoYPosition(0);
    } else {
      dispatch({ type: 'videoSize', payload: 'minimize' });
    }
  }, [isAppointmentPage, dispatch, isMaximize]);

  const streamCreated = useCallback(
    ({ stream }) => {
      if (!isEqual(stream.videoDimensions, anotherWindowDimensions)) {
        setAnotherWindowDimensions(stream.videoDimensions);
      }
    },
    [anotherWindowDimensions, setAnotherWindowDimensions],
  );

  if (!appointmentId || !sessionId || !token) {
    return null;
  }

  if (!isVideoCallJoined) {
    return null;
  }

  const onStopDrag = (e, data) => {
    setVideoXPosition(data.lastX);
    setVideoYPosition(data.lastY);
  };

  return (
    <>
      {publisherHasVideoError && <div className={classes.subscriberNoCamera}>{texts.noCameraOrMicrophoneAvailable}</div>}
      {isVideoCallJoined && !isAppointmentPage && (
        <BackToAppointmentLink setVideoYPosition={setVideoYPosition} setVideoXPosition={setVideoXPosition} />
      )}
      <Draggable disabled={isMaximize} position={{ x: videoXPosition, y: videoYPosition }} onStop={onStopDrag}>
        <div data-testid="video" className={classes.container} ref={containerRef}>
          <OTSession
            eventHandlers={{ streamCreated }}
            apiKey={window.env.REACT_APP_OPENTOK_API_KEY}
            sessionId={sessionId}
            token={token}>
            {isVideoCallJoined && <VideoPublisher />}
            <VideoSubscriber />
          </OTSession>
          {isVideoCallJoined && <VideoPanel />}
          {!isVideoCallActive && <div className={classes.paused}>{texts.paused}</div>}
        </div>
      </Draggable>
    </>
  );
};

export default preloadScript(OpentokVideo);
