import dayjs from 'dayjs';

import { mapAppointments } from './appointments';
import { Patient } from '../types/patient';
import { Note } from '../types/note';

export const serializePatient = (patient: Patient) => {
  if (!patient) {
    return null;
  }
  const createdAt = patient.createdAt.toISOString?.();
  let lastMessage;
  if (patient.lastMessage) {
    const lastMessageCreatedAt = patient.lastMessage.createdAt.toISOString?.();
    lastMessage = { ...patient.lastMessage, createdAt: lastMessageCreatedAt };
  }
  return { ...patient, createdAt, lastMessage };
};

export const mapPatient = (patient: Patient) => {
  const createdAt = dayjs(patient.createdAt);
  let lastMessage;
  if (patient.lastMessage) {
    const lastMessageCreatedAt = dayjs(patient.lastMessage.createdAt);
    lastMessage = { ...patient.lastMessage, createdAt: lastMessageCreatedAt };
  }
  const appointments = mapAppointments(patient.appointments);
  return { ...patient, lastMessage, createdAt, appointments };
};

export const getTodayNote = (patient: Patient) => {
  return patient?.notes.find((n: Note) => n.date.isSame(dayjs(), 'day')) || null;
};
