import { useLayoutEffect, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import { jssPreset } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { create as jssCreate } from 'jss';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { useUser } from './hooks/use_user';
import { useToken } from './hooks/use_token';
import { useLogoutListener } from './hooks/use_logout_listener';
import MainApp from './organism/main_app';
import { withReduxProvider } from './redux/store';
import { snackbarRef } from './utils/snackbar';
import { handleHotjarScript } from './utils/hotjar';
import { refreshAction } from './actions/refresh_action';
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const jss = jssCreate({ plugins: [...jssPreset().plugins] });

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3263E2',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: `"RedHatText","Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
const App = () => {
  const user = useSelector((state) => state.user);
  const isTester = user?.realityType === 'tester';
  const dispatch = useDispatch();
  useUser();
  useToken();
  useLogoutListener();

  useEffect(() => {
    dispatch(refreshAction('supplements'));
    dispatch(refreshAction('medications'));
  }, [dispatch]);

  useLayoutEffect(() => {
    const redirectToCorrectUrlIfNeeded = () => {
      const isHerokuProdURL = window.location.href.includes('gitrak-web-prod.herokuapp');
      if (isHerokuProdURL) {
        window.location.href = window.location.href.replace('gitrak-web-prod.herokuapp', 'providers.gitrak');
      }
    };
    redirectToCorrectUrlIfNeeded();
  }, []);

  useLayoutEffect(() => {
    handleHotjarScript(isTester);
  }, [isTester]);

  const closeSnackbar = () => {
    snackbarRef.current.closeSnackbar();
  };

  return (
    <StylesProvider jss={jss}>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            ref={snackbarRef}
            autoHideDuration={2500}
            maxSnack={1}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            action={() => <CloseIcon key="1" onClick={closeSnackbar} style={{ cursor: 'pointer' }} />}>
            <BrowserRouter>
              <MainApp />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  );
};

export default withReduxProvider(App);
