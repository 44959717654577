import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

export const INITIAL_FIRST_DAY_DATE = dayjs().startOf('isoWeek');
export const INITIAL_SELECTED_TAB = 'feeling';
export const INITIAL_SELECTED_MEAL = 'all';

export const dailyQuestionnaireFirstDayDate = (firstDayDate: Dayjs) => ({
  type: 'dailyQuestionnaireFirstDayDate',
  payload: firstDayDate,
});

export const setDailyQuestionnaireQuestion = (selectedQuestion: string) => ({
  type: 'dailyQuestionnaireSelectedTab',
  payload: selectedQuestion,
});

export const setDailyQuestionnaireMeal = (meal: string) => ({
  type: 'dailyQuestionnaireSelectedMeal',
  payload: meal,
});
