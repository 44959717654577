import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken } from '../actions/auth';
import dayjs from 'dayjs';

import { getUsersPermissions } from '../actions/permissions';
import { SystemState } from '../types/system_state';

export const useToken = () => {
  const [fetchedData, setFetchedData] = useState(false);
  const userId = useSelector((state: SystemState) => state.user?.userId);
  const isAdmin = useSelector((state: SystemState) => state.user?.groups?.admin);
  const accessToken = useSelector((state: SystemState) => state.accessToken);
  const accessTokenExpiredAt = useSelector((state: SystemState) => state.accessTokenExpiredAt);
  const dispatch = useDispatch();

  useEffect(() => {
    let handler: any;
    if (userId && accessToken && accessTokenExpiredAt) {
      let interval = Math.abs(dayjs().diff(accessTokenExpiredAt.clone?.().subtract(200, 'second'), 'second')) * 1000;
      handler = setInterval(() => dispatch(refreshToken()), interval);
    } else {
      clearInterval(handler);
    }
    return () => {
      clearInterval(handler);
    };
  }, [userId, accessToken, accessTokenExpiredAt, dispatch]);

  useEffect(() => {
    if (fetchedData || !userId || !accessToken) {
      if (!userId) {
        setFetchedData(false);
      }
      return;
    }
    dispatch(refreshToken());
    if (isAdmin) {
      dispatch(getUsersPermissions());
    }
    setFetchedData(true);
  }, [accessToken, dispatch, fetchedData, isAdmin, userId]);
};
