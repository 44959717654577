import axios from 'axios';
import { setUser as setSentryUser } from '@sentry/browser';
import dayjs, { Dayjs } from 'dayjs';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { SystemState } from '../types/system_state';
import { WeekdayWorkSlot } from '../types/weekday_work_slot';
import { getFullName, mapUser } from '../utils/user';
import { DateOfficeSlot } from '../types/date_office_slot';
import { Provider } from '../types/provider';

export const RESET_STORE = 'reset store';

const deleteAllCookies = () => {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
};

export const logout = ({ raiseLogoutEvent = true } = {}) => {
  if (raiseLogoutEvent) {
    window.localStorage.setItem('logout-event', 'logout');
  }
  deleteAllCookies();
  window.localStorage.clear();
  setSentryUser(null);
  return { type: RESET_STORE };
};

export const mapWeekdayWorkHours = (weekdayWorkHours: WeekdayWorkSlot[]): WeekdayWorkSlot[] => {
  return weekdayWorkHours?.map((wwh: WeekdayWorkSlot) => ({
    ...wwh,
    startHour: dayjs(wwh.startHour, 'HH:mm'),
    endHour: dayjs(wwh.endHour, 'HH:mm'),
  }));
};

export const mapDateOfficeHours = (dateOfficeHours: DateOfficeSlot[]): DateOfficeSlot[] => {
  return dateOfficeHours?.map((dateOfficeHour: DateOfficeSlot) => ({
    ...dateOfficeHour,
    startHour: dayjs(dateOfficeHour.startHour, 'HH:mm'),
    endHour: dayjs(dateOfficeHour.endHour, 'HH:mm'),
    date: dayjs(dateOfficeHour.date, 'YYYY-MM-DD'),
  }));
};

export const setToken = (data: SystemState): ThunkAction<void, SystemState, unknown, Action<string>> => {
  return async (dispatch) => {
    const { accessToken, refreshToken, accessTokenExpiredAt } = data;
    const momentAccessTokenExpiredAt: Dayjs = dayjs().add(Number(accessTokenExpiredAt), 'second');
    dispatch({ type: 'accessTokenExpiredAt', payload: momentAccessTokenExpiredAt });
    dispatch({ type: 'accessToken', payload: accessToken });
    dispatch({ type: 'refreshToken', payload: refreshToken });
  };
};

export const setUser = (data: Provider): ThunkAction<void, SystemState, unknown, Action<string>> => {
  return async (dispatch) => {
    const user = mapUser(data);
    setSentryUser({ id: String(user?.userId), username: getFullName(user) });
    dispatch({ type: 'user', payload: user });
  };
};

export const refreshToken = (): ThunkAction<void, SystemState, unknown, Action<string>> => async (dispatch, getState) => {
  const { refreshToken, accessToken, user } = getState();
  if (user?.userId) {
    const { data } = await axios.post('auth/token/refresh/', { refresh: refreshToken, accessToken, userId: user.userId });
    dispatch(setToken(data));
  }
};

export const refreshUser = (): ThunkAction<void, SystemState, unknown, Action<string>> => async (dispatch, getState) => {
  const { user } = getState();
  if (user?.userId) {
    const { data } = await axios.get(`users/${user.userId}/`);
    dispatch(setUser(data));
  }
};
