import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';

import Icon from './svg_icon';
import { SystemState } from '../types/system_state';

interface cssProps {
  isEnabled: boolean;
  isMaximize: boolean;
}

const useClasses = makeStyles((theme) => ({
  container: ({ isEnabled, isMaximize }: cssProps) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isEnabled ? 'white' : theme.palette.primary.main,
    minWidth: isMaximize ? 44 : 34,
    maxWidth: isMaximize ? 44 : 34,
    height: isMaximize ? 44 : 34,
    borderRadius: '50%',
    marginRight: 8,
    '&:hover': {
      backgroundColor: 'rgb(255, 255, 255, 0.8)',
    },
  }),
  containerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Props {
  iconName: string;
  toolTipText: string;
  iconSize: number;
  onClick: () => void;
  isEnabled: boolean;
}

const VideoPanelButton: React.FC<Props> = ({ iconName, toolTipText, onClick, isEnabled, iconSize }) => {
  const videoSize = useSelector<SystemState>((s) => s.videoSize);
  const isMaximize = videoSize === 'maximize';
  const classes = useClasses({ isEnabled, isMaximize });

  return (
    <Tooltip title={toolTipText} placement="top">
      <Button className={classes.container} onClick={onClick}>
        <div className={classes.containerContent}>
          <Icon name={iconName} fill={isEnabled ? 'black' : 'white'} width={iconSize} height={iconSize} />
        </div>
      </Button>
    </Tooltip>
  );
};

export default VideoPanelButton;
