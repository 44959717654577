import { combineReducers } from 'redux';
import dayjs from 'dayjs';

import { RESET_STORE } from '../actions/auth';
import { INITIAL_FIRST_DAY_DATE, INITIAL_SELECTED_TAB, INITIAL_SELECTED_MEAL } from '../actions/daily_questionnaire';
import { Dictionary } from '../types/global';
import { SystemState } from '../types/system_state';

import texts from '../utils/texts';

export interface ReduxAction {
  type: string;
  payload: any;
}

const createReducer = (actionType: string, initialState: any) => (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionType:
      return action.payload;
    default:
      return state;
  }
};

const reducersDefaultValues: Dictionary<any> = {
  accessToken: '',
  refreshToken: null,
  user: null,
  userPermissions: [],
  currentAppointmentId: null,
  usersInfo: {},
  selectedPatient: null,
  detailedPatients: {},
  dailyQuestionnaireFirstDayDate: INITIAL_FIRST_DAY_DATE,
  dailyQuestionnaireSelectedTab: INITIAL_SELECTED_TAB,
  dailyQuestionnaireSelectedMeal: INITIAL_SELECTED_MEAL,
  selectedMicrophone: null,
  selectedCamera: null,
  availableDevices: null,
  anotherWindowDimensions: null,
  isVideoCallActive: true,
  appointmentShareVideo: false,
  appointmentShareAudio: false,
  videoSize: 'maximize',
  isVideoCallJoined: false,
  messages: null,
  accessTokenExpiredAt: null,
  searchedPatients: null,
  selectedDailyQuestionnaire: null,
  searchedNotes: null,
  currentFiveDates: [],
  videoScroll: 0,
  isFileUploading: false,
  chatSelectedUser: null,
  chatSearchedUsers: [],
  libraryFiles: null,
  searchedLibraryFiles: null,
  tfaFirstDigitRef: null,
  publisherHasVideoError: false,
  noteFilesAnchorEl: null,
  documentsAnchorEl: null,
  progressPercent: null,
  messageInputAnchorEl: null,
  libraryFile: null,
  librarySelectedFile: null,
  draftNote: null,
  isSendMessageModalOpen: false,
  sendMessageSelectedPatient: false,
  draftLabTests: null,
  tamarPatients: [],
  openedDrawerName: null,
  reschedulingAppointment: null,
  schedulingAppointmentTab: texts.manually,
  schedulingAppointmentPickASlotTab: texts.calendar,
  availableSlots: {},
  appointmentSelectedDatetime: dayjs(),
  hasSelectedAppointmentHour: false,
  openedModalName: null,
  allDocuments: null,
  chatMessageText: '',
  chatMessageInputHeight: 0,
  medications: [],
  supplements: [],
  smartyStreetsAddress: null,
  selectedDietitianIdReport: null,
  patientsReportTableSearchTerm: null,
  patientsReportTableSoryBy: 'lastNotDoneAt'
};

const reducers: Dictionary<any> = {};
Object.keys(reducersDefaultValues).forEach((key) => {
  reducers[key] = createReducer(key, reducersDefaultValues[key]);
});

const appReducer = combineReducers(reducers);

const rootReducer: any = (state: Dictionary<SystemState> | undefined, action: ReduxAction) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
