import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  h3: {
    fontSize: 20,
    fontWeight: 500,
  },
});

interface Props {
  className?: string;
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}

const H3: React.ComponentType<Props> = React.forwardRef(({ className, children }: Props, ref?: React.Ref<HTMLDivElement>) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classNames(classes.h3, className)}>
      {children}
    </div>
  );
});

export default H3;
