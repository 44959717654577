import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Button from '@material-ui/core/Button';

import Icon from './svg_icon';

const useStyles = makeStyles({
  button: {
    backgroundColor: 'rgba(50, 99, 226, 0.15)',
    width: 40,
    height: 40,
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRoot: {
    minWidth: 40,
  },
  menuPaper: {
    marginTop: 40,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  },
});

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  name: string;
  children?: React.ReactNode;
}

const AppIcon: React.FC<Props> = ({ onClick, name, children }: Props) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div>
      <Button className={classes.button} classes={{ root: classes.buttonRoot }} onClick={onClick}>
        {children ? children : <Icon name={name} fill={theme.palette.primary.main} />}
      </Button>
    </div>
  );
};

export default AppIcon;
