import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';

import texts from '../utils/texts';
import H3 from '../gi_styles/h3';
import Body from '../gi_styles/body';
import Button from '../gi_styles/button';
import Backdrop from '../organism/backdrop';
import MediaSourceOptions from './media_source_options';
import { useReduxState } from '../hooks/use_redux_state';
import { useMediaSourceOptions } from '../hooks/use_media_source_options';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '60%',
    maxWidth: 700,
    height: '80%',
    maxHeight: 600,
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 45,
    borderRadius: 20,
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    marginBottom: 40,
    textAlign: 'center',
  },
  contentContainer: {
    width: '80%',
  },
  media: {
    marginBottom: 40,
  },
  mediaTitle: {
    marginBottom: 20,
  },
  button: {
    width: '60%',
    alignSelf: 'center',
  },
  alert: {
    marginTop: 10,
    color: 'red',
  },
});

const VideoSettingsModal = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const [shareVideo] = useReduxState({ key: 'appointmentShareVideo' });
  const [shareAudio] = useReduxState({ key: 'appointmentShareAudio' });
  useMediaSourceOptions({ intervalDelay: 1000 });

  const toggleIsOpen = () => setIsOpen((v: boolean) => !v);

  const close = () => {
    toggleIsOpen();
  };

  const isSafari = window.safari !== undefined;
  return (
    <Modal
      className={classes.modal}
      BackdropComponent={() => <Backdrop hide={toggleIsOpen} />}
      open={isOpen}
      onClose={toggleIsOpen}>
      <div className={classes.container}>
        <div className={classes.contentContainer}>
          <H3 className={classes.title}>{texts.moreOptions}</H3>
          <div className={classes.media}>
            <H3 className={classes.mediaTitle}>{texts.cameraSettings}</H3>
            <MediaSourceOptions selectedDeviceReduxKey="selectedCamera" kind="videoinput" disabled={!shareVideo} />
            {!shareVideo && <Body className={classes.alert}>{texts.yourVideoOff}</Body>}
          </div>
          <div className={classes.media}>
            <H3>{texts.microphoneSettings}</H3>
            <Body className={classes.mediaTitle}>{texts.speakerDependMicrophone}</Body>
            <MediaSourceOptions selectedDeviceReduxKey="selectedMicrophone" kind="audioinput" disabled={!shareAudio} />
            {isSafari && <Body className={classes.alert}>{texts.safariNotSupportAudioDevices}</Body>}
            {!shareAudio && <Body className={classes.alert}>{texts.youAreMute}</Body>}
          </div>
        </div>
        <Button className={classes.button} onClick={close} text={texts.close} />
      </div>
    </Modal>
  );
};

export default VideoSettingsModal;
