import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';

import Icon from './svg_icon';
import { isSameDevice } from '../utils/devices';

const useStyles = makeStyles({
  select: {
    display: 'flex',
  },
});

const MediaSourceOptions = ({ selectedDeviceReduxKey, kind, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedDevice = useSelector((s) => s[selectedDeviceReduxKey]);
  let devices = useSelector((s) => s.availableDevices);
  devices = devices?.filter((d) => d.kind === kind);

  if (!devices?.length) {
    disabled = true;
  }

  const onSelect = (newDevice) => {
    if (!isSameDevice(selectedDevice, newDevice)) {
      dispatch({ type: selectedDeviceReduxKey, payload: newDevice });
    }
  };

  const buildLabel = (label) => {
    return label?.replace(/ \(([A-z0-9]){4}:([A-z0-9]){4}\)$/, '') || '';
  };

  const selectedLabel = buildLabel(selectedDevice?.label);
  return (
    <Select
      className={classes.select}
      value={selectedLabel}
      variant="outlined"
      disabled={disabled}
      IconComponent={(props) => <Icon name="down_arrow" fill="black" {...props} />}>
      {devices?.map((device) => (
        <MenuItem key={device.deviceId} value={buildLabel(device.label)} onClick={() => onSelect(device)}>
          {buildLabel(device.label)}
        </MenuItem>
      ))}
    </Select>
  );
};

MediaSourceOptions.propTypes = {
  selectedDeviceReduxKey: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['videoinput', 'audioinput']).isRequired,
  disabled: PropTypes.bool,
};

export default MediaSourceOptions;
