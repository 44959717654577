import { WeekdayWorkSlot } from '../types/weekday_work_slot';
import { mapAppointments, serializeAppointments } from './appointments';
import { Patient } from '../types/patient';
import { Provider } from '../types/provider';
import { serializePatient, mapPatient } from './patients';
import { mapDateOfficeHours, mapWeekdayWorkHours } from '../actions/auth';
import { DateOfficeSlot } from '../types/date_office_slot';

export const serializeUser = (user: Provider) => {
  if (!user) {
    return null;
  }
  const weekdayWorkHours = user.weekdayWorkHours?.map((wwh: WeekdayWorkSlot) => ({
    ...wwh,
    startHour: wwh.startHour.format('HH:mm:ss'),
    endHour: wwh.endHour.format('HH:mm:ss'),
  }));
  const dateWorkHours = user.dateWorkHours?.map((wwh: DateOfficeSlot) => ({
    ...wwh,
    date: wwh.date.format('YYYY-MM-DD'),
    startHour: wwh.startHour.format('HH:mm:ss'),
    endHour: wwh.endHour.format('HH:mm:ss'),
  }));
  const workBreaks = user.workBreaks?.map((wwh: DateOfficeSlot) => ({
    ...wwh,
    date: wwh.date.format('YYYY-MM-DD'),
    startHour: wwh.startHour.format('HH:mm:ss'),
    endHour: wwh.endHour.format('HH:mm:ss'),
  }));
  const appointments = serializeAppointments(user.appointments);
  const unBilled = serializeAppointments(user.unBilled);
  const patients = user.patients.map((p: Patient) => serializePatient(p));
  return { ...user, weekdayWorkHours, patients, appointments, unBilled, dateWorkHours, workBreaks };
};

export const mapUser = (user: Provider) => {
  const weekdayWorkHours = mapWeekdayWorkHours(user.weekdayWorkHours);
  const dateWorkHours = mapDateOfficeHours(user.dateWorkHours);
  const workBreaks = mapDateOfficeHours(user.workBreaks);
  const unBilled = [...mapAppointments(user.unBilled)];
  const appointments = [...mapAppointments(user.appointments)];
  const patients = user.patients.map((p: Patient) => mapPatient(p));
  return { ...user, weekdayWorkHours, unBilled, patients, appointments, dateWorkHours, workBreaks };
};

interface FullName {
  firstName: string;
  middleName: string;
  lastName: string;
}

const removeMultipleSpaces = (name: string) => name.replace(/ +(?= )/g, '');

export const getFullName = (user: FullName | undefined): string => {
  if (!user) {
    return '';
  }
  let fullName = `${user?.firstName || ''} ${user?.middleName || ''} ${user?.lastName || ''}`;
  fullName = removeMultipleSpaces(fullName);

  return fullName;
};

export const parsePhone = (phone: string) => {
  if (!phone) {
    return null;
  }
  if (phone.startsWith('+1')) {
    return phone.replace('+1', '');
  }
  if (phone.startsWith('+972')) {
    return phone.replace('+972', '0');
  }
  return phone;
};
