const script = `
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 2573520, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');`
  .replace(/\n/g, '')
  .trim();

export const handleHotjarScript = (isTester) => {
  const s = document.createElement('script');
  if (!isTester) {
    s.type = 'text/javascript';
    s.innerText = script;
    s.id = 'hotjarScript';
    document.getElementsByTagName('head')[0].appendChild(s);
  } else {
    const element = document.getElementById('hotjarScript');
    element?.parentNode?.removeChild?.(element);
  }
};
