import { createPortal } from 'react-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { CircularProgressbar as ReactCircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { useReduxState } from '../hooks/use_redux_state';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    zIndex: 10,
    height: 80,
    width: 80,
  },
});

const CircularProgressbar = () => {
  const [progressPercent] = useReduxState({ key: 'progressPercent' });
  const classes = useStyles();
  const theme = useTheme();

  const styles = {
    path: {
      stroke: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  };

  if (!Number.isInteger(progressPercent)) {
    return null;
  }

  const progress = Math.floor(progressPercent);
  return createPortal(
    <div className={classes.container}>
      <ReactCircularProgressbar value={progress} text={`${progress}%`} styles={styles} />
    </div>,
    document.getElementById('progressbar-root'),
  );
};

export default CircularProgressbar;
