/* eslint-disable import/first */
import ReactDOM from 'react-dom';
import isEmpty from 'lodash/isEmpty';

import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();
window.env = isEmpty(env) ? process.env : env;
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import { initSentry } from './utils/sentry';
import { initAxios } from './utils/axios';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

initAxios();

initSentry();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
