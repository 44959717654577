import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import texts from '../utils/texts';
import SmallButton from '../gi_styles/small_button';
import VideoSettingsModal from './video_settings_modal';

const useClasses = makeStyles({
  button: {
    backgroundColor: '#222222',
    minWidth: 70,
    width: 70,
    minHeight: 23,
    height: 23,
    '&:hover': {
      backgroundColor: 'rgb(255, 255, 255, 0.8)',
    },
  },
  text: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 10,
    padding: 5,
  },
});

const VideoPanelSettings: React.FC<{}> = () => {
  const classes = useClasses();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button className={classes.button} onClick={() => setIsOpen(true)}>
        <SmallButton className={classes.text}>{texts.settings}</SmallButton>
      </Button>
      <VideoSettingsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default VideoPanelSettings;
