import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import MuiButton from '@material-ui/core/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import texts from '../utils/texts';
import H3 from '../gi_styles/h3';
import Button from '../gi_styles/button';
import Icon from './svg_icon';
import Backdrop from '../organism/backdrop';
import { useReduxState } from '../hooks/use_redux_state';
import { refreshUser } from '../actions/auth';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
    borderRadius: 20,
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    width: '60%',
    marginBottom: 40,
    textAlign: 'center',
  },
  leave: {
    width: '90%',
    marginBottom: 15,
  },
  stay: {
    width: '90%',
    backgroundColor: '#DAE2F5',
    '&:hover': {
      backgroundColor: 'rgba(50, 99, 226, 0.3)',
    },
  },
  stayText: {
    color: theme.palette.primary.main,
  },
  closeIcon: {
    position: 'absolute',
    top: 30,
    right: 10,
    margin: 0,
    padding: 0,
  },
}));

const EndVideoCallDialog = ({ isConfirmOpen, setIsConfirmOpen }) => {
  const classes = useStyles();
  const [, setIsVideoCallJoined] = useReduxState({ key: 'isVideoCallJoined' });
  const isAppointmentPage = !!useRouteMatch('/appointments/:appointmentId/:tab?');
  const [currentAppointmentId] = useReduxState({ key: 'currentAppointmentId' });
  const [, setDrawerName] = useReduxState({ key: 'openedDrawerName' });
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleIsConfirmOpen = () => setIsConfirmOpen((v: boolean) => !v);

  const closeCall = async () => {
    if (!isAppointmentPage) {
      history.push(`/appointments/${currentAppointmentId}`);
    }
    toggleIsConfirmOpen();
    setIsVideoCallJoined(false);
    await axios.put(`/appointments/${currentAppointmentId}/`, { status: 'done' });
    dispatch(refreshUser());
    setDrawerName('billing');
  };

  return (
    <Modal
      className={classes.modal}
      BackdropComponent={() => <Backdrop hide={toggleIsConfirmOpen} />}
      open={isConfirmOpen}
      onClose={toggleIsConfirmOpen}>
      <div className={classes.container}>
        <H3 className={classes.title}>{`${texts.leaveAppointment}?`}</H3>
        <MuiButton className={classes.closeIcon} onClick={toggleIsConfirmOpen}>
          <Icon name="close" fill="black" width={32} height={32} />
        </MuiButton>
        <Button className={classes.leave} onClick={closeCall} text={`${texts.yes}, ${texts.continueBilling}`} />
        <Button
          className={classes.stay}
          onClick={toggleIsConfirmOpen}
          textClassName={classes.stayText}
          text={`${texts.no}, ${texts.goBackAppointment}`}
        />
      </div>
    </Modal>
  );
};

export default EndVideoCallDialog;
