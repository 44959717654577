const texts = {
  generalError: 'Sorry, An error has occurred',
  phone: 'Phone',
  secondPhone: 'Second Phone',
  backToAppointment: 'Back to appointment',
  password: 'Password',
  login: 'Login',
  addPatient: 'Add Patient',
  patientAdded: 'Patient added',
  appointmentManagement: 'Appointment Management',
  lastDoneAppointment: 'Last Done Appointment',
  numberDoneAppointments: 'Number of done appointments',
  lastCanceledNotShowAppointment: 'Date of canceled / not show appointment',
  giTrak: 'GI-Trak',
  followYourPatientsBetter: 'Follow your patients better',
  required: 'Required',
  chiefComplaint: 'Chief complaint',
  note: 'Note',
  insuranceCardFrontImage: 'Insurance card front image',
  insuranceCardBackImage: 'Insurance card back image',
  insuranceName: 'Insurance name',
  showImage: 'Show image',
  assessment: 'Assessment',
  sign: 'Sign',
  paymentMethod: 'Payment method',
  registration: 'Registration',
  appointmentNote: 'Appointment Note',
  internalNote: 'Internal Note',
  appointmentsDone: 'Appointments done',
  firstAppointment: 'First appointment',
  activePatients: 'Active Patients',
  illnessHistory: 'Illness History',
  totalInformation: 'Total information',
  totalRegistrations: 'Total registrations',
  editMyInsurance: 'Edit my Insurance',
  incoming: 'Incoming',
  initial: 'Initial',
  secondFollowUp: 'Second follow up',
  followUp: 'Follow up',
  allergies: 'Allergies',
  familyHistory: 'Family history',
  feedback: 'Feedback',
  totalVisits: 'Total visits',
  totalActiveUsers: 'Total active users',
  totalPastActiveUsers: 'Total past active users',
  totalFutureVisits: 'Total future visits',
  paused: 'Paused',
  payerName: 'Payer name',
  editInfo: 'Edit Info',
  canceled: 'Canceled',
  phoneAppointmentOnly: 'This is a phone appointment only',
  rescheduled: 'Rescheduled',
  activity: 'Activity',
  last7Days: 'Last 7 Days',
  last28Days: 'Last 28 Days',
  graph: 'Graph',
  engagements: 'Engagements',
  monthly: 'Monthly',
  futureAppointments: 'Future appointments',
  activityGraph: 'Activity Graph',
  appointmentAt: 'Appointment at',
  dietitianName: 'Dietitian Name',
  selectDietitian: 'Select dietitian',
  comment: 'Comment',
  pastBookedAppointment: 'Past Booked Appointment',
  futureBookedAppointment: 'Future Booked Appointment',
  lastAppointment: 'last Appointment',
  totalAppointment: 'Total Appointment',
  totalMessages: 'Total Messages',
  lastMessage: 'Last Message',
  missedAppointments: 'Missed Appointments',
  patientsWithoutAppointments: 'Patients without appointments',
  patientsWithPastAppointments: 'Patients with past appointments',
  patientsDetails: 'Patients Details',
  addNewFood: 'Add new food',
  dietitianForum: "Dietitian's Forum",
  patientDietPlan: 'Patient Diet Plan',
  isActive: 'Is active',
  insuranceCardFront: 'Front insurance card',
  insuranceCardBack: 'Back insurance card',
  notActive: 'Not active',
  active: 'Active',
  female: 'Female',
  initialQuestionnaireEdit: 'Edit initial questionnaire',
  complete: 'Complete',
  missed: 'Missed',
  'not-started': 'Not started',
  notStarted: 'Not started',
  pdfOrImageOnly: 'Only pdf or image file are allowed',
  appointmentScheduled: 'Appointment has been scheduled',
  foodsToAvoid: 'Foods to avoid',
  lifestyle: 'Lifestyle',
  frequency: 'Frequency',
  supplements: 'Supplements',
  editInsurance: 'Edit Insurance',
  saveChanges: 'Save changes',
  editProfile: 'Edit Profile',
  downloadProviderApp: "Download our provider's app",
  medicalInfo: 'Medical Info',
  tags: 'Tags',
  specialties: 'Specialties',
  dateOverrides: 'Date overrides',
  addDatesWhenAvailabilityChanges: 'Add dates when your availability changes from your weekly hours',
  allDay: 'All day',
  vacationDays: 'Vacation days',
  blockOutDaysWhereNoAvailability: 'Block out days where you will have no availability',
  dateCustomization: 'Date customization',
  modifyAvailabilitySpecificDatesHours: 'Modify time availability for specific dates that differ from your weekly hours  ',
  breakHours: 'Break hours',
  value: 'Value',
  bufferTimeBetweenAppointments: 'Buffer time between appointments',
  addNewLabTest: 'to add new lab test',
  noRecentLabTests: 'No recent lab tests',
  fileSent: 'File Sent',
  created: 'Created',
  availableAppointmentsInIncrementsOf: 'Show available appointments in increments of',
  editNote: 'Edit note',
  resume: 'Resume',
  notInRange: 'Not in range',
  labTests: 'Lab tests',
  sendNewFile: 'Send new file',
  selectPatientToSendFile: 'Select the patient you would like to send the file',
  noFilesFound: 'No files found',
  youAreMute: 'Your microphone is muted, please unmute to change microphone',
  yourVideoOff: 'Your camera is off, please turn it on to change camera',
  outlookFirstName: 'Outlook first name',
  outlookLastName: 'Outlook last name',
  view: 'View',
  addTag: 'Add tag',
  searchFile: 'Search file',
  fromLocalComputer: 'From local computer',
  fromLibrary: 'From library',
  startAppointment: 'Start appointment',
  pauseCall: 'Pause call',
  resumeCall: 'Resume call',
  becauseDidNotAllowAccessCalendar: 'because you did not allow us to access the calendar',
  endCall: 'End call',
  noSelectedPatient: 'No selected patient',
  turnOnCamera: 'Turn on camera',
  turnOffCamera: 'Turn off camera',
  type: 'Type',
  joinAgain: 'Join Again',
  passed: 'Passed',
  copay: 'Copay',
  priceBiggerThenZero: 'Price need to be greater then zero',
  emailIsNotValid: 'Email is not valid',
  passwordTooShort: 'Password too short',
  passwordIsCommon: 'Password is common',
  passwordTooSimilar: 'Password is too similar',
  phoneIsNotValid: 'Phone is not valid',
  payers: 'Payers',
  userIsProvider: 'Can not add provider user',
  price: 'Price',
  submit: 'Submit',
  insuranceClaimDetails: 'Insurance claim details',
  appointmentType: 'Appointment type',
  appointmentDate: 'Appointment date',
  apartment: 'Apartment',
  rejected: 'Rejected',
  safariNotSupportAudioDevices:
    'Switching audio device is not well supported by Safari browser. Chrome browser will work better for you.',
  addressCouldNotFound: 'Sorry, your address could not be found',
  pending: 'Pending',
  startConversation: 'to start a conversation',
  reason: 'Reason',
  male: 'Male',
  speakerDependMicrophone: 'Your speaker and microphone are biding together',
  payment: 'Payment',
  doYouMean: 'Do you mean',
  useThisAddress: 'Use this address',
  noRecentMessages: 'No recent messages',
  cancelAppointment: 'Cancel appointment',
  reschedule: 'Reschedule',
  appointmentDetails: 'Appointment Details',
  dietitianCopay: 'Dietitian copay',
  patientName: 'Patient name',
  patientEmail: 'Patient email',
  patientPhone: 'Patient phone',
  typeToSearch: 'Type to search',
  patientAddress: 'Patient Address',
  patientPayer: 'Patient payer',
  pendingInsuranceApproval: 'Pending insurance approval',
  diagnosedDisease: 'Diagnosed disease',
  diagnosedDiseases: 'Diagnosed diseases',
  insuranceMemberId: 'Insurance member id',
  patientBirthDate: 'Patient birth date',
  patientGender: 'Patient gender',
  dietitianId: 'Dietitian ID',
  dietitianEmail: 'Dietitian email',
  dietitianPhone: 'Dietitian phone',
  dietitianAddress: 'Dietitian Address',
  dietitianPayers: 'Dietitian payers',
  dietitianBirthDate: 'Dietitian birth date',
  dietitianGender: 'Dietitian gender',
  payer: 'Payer',
  reject: 'Reject',
  address: 'Address',
  upComingAppointments: 'Upcoming Appointments',
  notes: 'Notes',
  joined: 'Joined',
  newMessage: 'New Message',
  searchYourMessages: 'Search your messages',
  messages: 'Messages',
  searchPatient: 'Search a patient',
  close: 'Close',
  message: 'Message',
  sent: 'Sent',
  microphoneSettings: 'Microphone settings',
  cameraSettings: 'Camera settings',
  moreOptions: 'More options',
  snacks: 'Snacks',
  selectPatientToMessage: 'Select the patient you would like to message',
  mute: 'Mute',
  unmute: 'Unmute',
  settings: 'Settings',
  stopVideo: 'Stop video',
  startVideo: 'Start video',
  agreement: 'Agreement',
  functionalDietitian: 'Functional Dietitian',
  dietitian: 'Dietitian',
  typeOfDietitian: 'Type of Dietitian',
  medical: 'Medical',
  contractorAgreement: 'Contractor Agreement',
  thisAppointment: 'This appointment',
  appointmentWith: 'Your appointment with',
  hasEnded: 'has ended',
  totalTime: 'Total time',
  sendClaim: 'Send Claim',
  insuranceClaim: 'Insurance Claim',
  clear: 'Clear',
  selectWorkDays: 'Select your work days',
  willAddMoreDetailsLater: 'Don’t worry, we will add more details to your calendar later',
  searchPastNotes: 'Search past notes',
  addNewNote: 'Add a new note',
  diet: 'Diet',
  other: 'Other',
  appointmentTime: 'Appointment Time',
  initialQuestionnaire: 'Initial Questionnaire',
  backToAppointmentRoomLobby: 'Back to appointment room lobby',
  olderPatientNotes: 'Older Patient Notes',
  patientNote: 'Patient Notes',
  noNotes: 'No available notes',
  stool: 'Stool',
  startsIn: 'starts in',
  bleedingInStool: 'Bleeding in stool',
  noAppointmentsHistory: 'No Appointments History',
  noUpcomingAppointments: 'No Upcoming Appointments',
  notesAttached: 'Notes Attached',
  posted: 'Posted',
  nextAppointmentWith: 'Your next appointment with',
  dailyQuestion: 'Daily Questions',
  outgoing: 'Outgoing',
  viewMore: 'View More',
  appointmentRoom: 'Appointment Room',
  noData: 'No data',
  dateOfBirth: 'Date of birth',
  diagnosis: 'Diagnosis',
  dateOfDiagnosis: 'Date of Diagnosis',
  documents: 'Documents',
  patientNotes: 'Patient Notes',
  basicInformation: 'Basic Information',
  patientInfo: 'Patient info',
  dietPlan: 'Diet plan',
  dailyQuestions: 'Daily questions',
  addNote: 'Add note',
  suggestAppointment: 'Suggest Appointment',
  sendMessage: 'Send Message',
  notValid: 'Not valid',
  sortBy: 'Sort By',
  lastNotDoneAt: 'Last Not Done Appointment Date',
  lastDoneAt: 'Last Attended Consult',
  pastSurgeries: 'Past surgeries',
  bloodyInStool: 'Bloody stool',
  currentDiets: 'Current diet(s)',
  oldDiets: 'Past diet(s)',
  oldDietsImprovement: 'Overall Improvement',
  greenStools: 'Green stool',
  blackStools: 'Black stool',
  fullName: 'Full name',
  currentMedications: 'Current medication(s)',
  oldMedications: 'Past medication(s)',
  currentSupplements: 'Current supplements',
  medicalHistory: 'Medical history',
  hasSkinConditions: 'Skin conditions',
  skinConditionsFreeText: 'Skin conditions details',
  overallStressLevel: 'Stress level',
  sleepHoursAverage: 'Average hours slept every night',
  choosePayer: 'Choose payer',
  invalidCredentials: 'invalid credentials',
  fiveCharacterLong: '5 character Long',
  zipCodeIsNotValid: 'Zip code is not valid',
  initialAppointmentDuration: 'Initial appointment duration',
  secondFollowUpAppointmentDuration: 'Second follow up appointment duration',
  generalFollowUpAppointmentDuration: 'General follow up appointment duration',
  initialAppointmentPrice: 'Initial appointment price',
  secondFollowUpAppointmentPrice: 'Second follow up appointment price',
  generalFollowUpAppointmentPrice: 'General follow up appointment price',
  onlyNumbersAllowed: 'Only numbers allowed',
  noAppointmentSummary: 'There is no summary on this appointment',
  noAppointmentsToday: 'No appointments today',
  noPatientSummaries: 'There are no summaries for this patient',
  approveUserMedia: 'Please approve using microphone and camera.',
  thanks: 'Thanks',
  welcomeToYour: 'Welcome to your',
  gitrakDashboard: 'GI Trak Dashboard',
  takeLookAndStartSettingWorkPlans: 'Take a look around and start setting up your work plans',
  appointmentHistory: 'Appointment History',
  selectAllApply: 'Please select all that apply',
  setWeeklyHours: 'Set your weekly hours',
  noRecentActivity: 'No recent activity.',
  patientActivity: 'Patient activity',
  free: 'Free',
  edit: 'Edit',
  startNow: 'Start now',
  startingSoon: 'Starting soon',
  viewCalendar: 'View Calendar',
  checkMyPatients: 'Check in on my patients',
  editTodaySchedule: 'Edit today’s schedule',
  viewReportingStats: 'View reporting stats',
  goodMorning: 'Good morning',
  goodAfternoon: 'Good afternoon',
  goodDay: 'Good Day',
  goodEvening: 'Good evening',
  searchPatients: 'Search your patients',
  setYourCalendar: 'Set up your calendar',
  easilyManageSchedule: 'Easily manage your schedule',
  syncCalendar: 'Sync calendar',
  syncYourCalendar: 'Sync your calendar',
  combineAppointmentsEventsOnePlace:
    'Combine your appointments and events in one place. ' +
    '(Don’t worry, your calendar information will remain private and we will not have access to make any changes.)',
  interview: 'Interview',
  profile: 'Profile',
  totalPatients: 'Total Patients',
  newPatients: 'New Patients',
  officeHours: 'Office hours',
  dailyOfficeHours: 'Daily office hours',
  weekdayOfficeHours: 'Weekly office hours',
  welcomeToGiTrak: 'Welcome to GI Trak!',
  completeProfilePictureCoverPhoto: 'Let’s complete your profile before starting. Please add a picture and a cover photo',
  onboardingEndSubTitle:
    'Thank you for inputing your information. One of our representatives will be reaching out to conduct an interview before completing your profile',
  approve: 'Approve',
  decline: 'Decline',
  startHourAfterEndHour: 'Start hour need to be after end hour',
  yes: 'Yes',
  continueBilling: 'continue to billing',
  comingSoon: 'Coming Soon',
  sendUsEmail: 'Send us an email',
  chatSupport: 'Chat with Support',
  finish: 'Finish',
  goBackAppointment: 'go back to appointment',
  backToAllPatients: 'Back To all patients',
  partOne: 'PART ONE',
  partTwo: 'PART TWO',
  partThree: 'PART THREE',
  insuranceDetails: 'Insurance details',
  addInsuranceInformation: 'Add in your insurance information',
  addAddressInformation: 'Add your address information',
  addAppointment: 'Add Appointment',
  appointmentAdding: 'Add Appointment',
  appointmentScheduling: 'Reschedule Appointment',
  calendar: 'Calendar',
  list: 'List',
  noAvailableHours: 'No time slots available',
  manually: 'Manually',
  pickASlot: 'Pick a slot',
  addressDetails: 'Address details',
  uploadDocuments: 'Upload Documents',
  addCopyCertificationCard: 'Add copy of Certification Card',
  addMalpracticeInsuranceDocument: 'Add Malpractice Insurance Document',
  addResumeDocument: 'Add Resume Document',
  addAdvancedCertification: 'Add Advanced Certification',
  certificationCard: 'Certification Card',
  malpracticeInsurance: 'Malpractice Insurance',
  advancedCertification: 'Advanced Certification',
  acceptedInsurance: 'Accepted Insurance',
  selectAllThatApply: 'select all that apply',
  searchInsuranceCompanies: 'Search insurance companies',
  workDays: 'Work Days',
  SelectAllThatApply: 'Please select all that apply',
  businessInformation: 'Business information',
  getMoreBusinessDetails: 'Let’s get some more business details',
  specifyOther: 'If other, please specify',
  expertises: 'Expertise',
  selectExpertise: 'Select your expertise',
  TellAboutDietsSpecialize: 'Tell us about what kind of diets you specialize in',
  tooManyCharacters: 'Too many characters',
  jobTitle: 'Job Title',
  pleaseCheckYourInternetConnection: 'Please check your internet connection',
  privacyPolicy: 'Privacy Policy',
  signIn: 'Sign in',
  profileInfo: 'Profile info',
  businessInfo: 'Business info',
  insurance: 'Insurance',
  sex: 'Sex',
  snacksFoods: 'Snacks ideas',
  location: 'Location',
  createAccount: 'Create account',
  enterCodeSent: 'Enter the code sent to your phone',
  didNotGetCode: 'Didn’t get the code',
  resentCode: 'Resend the code',
  signInToAccount: 'Sign in to your account',
  agreeAndContinue: 'Agree and Continue',
  phoneNumber: 'Phone Number',
  createPassword: 'Create a Password',
  signUp: 'Sign up',
  chargeNow: 'Charge now',
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  syncCalendarDescription:
    'You can sync an existing calendar to have one place to track all your appointments.\n' +
    'Any changes made on a different calendar will automatically sync here.\n' +
    '(Don’t worry, your calendar information will remain private and we will not have access to make any changes.)',
  giTrakApplication: 'GI Trak Application',
  detailsBeforeSubmittingApplication: 'We’ll need some details before submitting your application',
  closeTheCallDescription: 'Are you sure you want to close the video appointment?',
  leaveAppointment: 'Are you sure you want to leave this appointment',
  answer: 'Answer',
  lastUpdated: 'Last updated',
  generalFeeling: 'General Feeling',
  feeling: 'Feeling',
  dailyAnswers: 'Daily Answers',
  all: 'All',
  breakfast: 'Breakfast',
  breakfastFoods: 'Breakfast ideas',
  lunch: 'Lunch',
  lunchFoods: 'Lunch ideas',
  dinner: 'Dinner',
  dinnerFoods: 'Dinner ideas',
  food: 'Food',
  allAnswers: 'All answers',
  cramping: 'Cramping',
  blood: 'Blood',
  status: 'Status',
  lastNotDoneStatus:'Last Not Done Appointment Status',
  painLevel: 'Pain level',
  stoolTimes: 'How often do you poop',
  stoolTexture: "Stool's texture",
  stoolConsistency: "My stool's were",
  dailyAnswersAndFood: 'daily answers & Food',
  taxIdIsNotValid: 'Tax id is not valid',
  npiIsNotValid: 'Npi is not valid',
  twoFactorCode: 'Two Factor Code',
  twoFactorCodeNotValid: 'Two factor code is not valid',
  enterTheCodeWeSentYou: 'Enter the code we sent you.',
  noPatients: 'There are no patients associated with you.',
  workPlan: 'Work plan',
  no: 'No',
  onlyDietitianOrAdminUserCanLogin: 'Only dietitian or admin user can login',
  todaysAppointments: 'Today’s Appointments',
  getReadyForYourNextAppointmentAt: 'Get ready for your next appointment at',
  stopAppointment: 'Stop appointment',
  yourAppointmentWillFinishInAbout: 'Your appointment will finish in about',
  upcomingVideoAppointments: 'Upcoming video appointments',
  connect: 'Connect',
  chargeAmount: 'Charge Amount',
  middleName: 'Middle Name',
  streetLine: 'Street line',
  streetLineOne: 'Street line 1',
  streetLineTwo: 'Street line 2',
  city: 'City',
  missingDate: 'Missing date',
  missingStartHour: 'Missing Start Hour',
  missingEndHour: 'Missing End Hour',
  endTimeCantBeAfterStartTime: "End time can't be after start time",
  eventAlreadyExist: 'Event already exist',
  date: 'Date',
  startAt: 'Start At',
  endAt: 'End At',
  start: 'Start',
  end: 'End',
  syncedWithGoogle: 'Synced Google Calendar',
  syncWithGoogle: 'Sync Google Calendar',
  syncWithOutlook: 'Sync Outlook Calendar',
  syncedWithOutlook: 'Synced Outlook Calendar',
  remove: 'Remove',
  removeCalendarConfirmation: 'Are you sure you want to remove calendar permission within the site?',
  confirm: 'Confirm',
  removeCalendar: 'Remove calendar',
  syncedWithApple: 'Synced With Apple',
  signInWithApple: 'Sign in with Apple',
  MissingWeekDay: 'Missing Week day',
  weekday: 'Weekday',
  day: 'Day',
  ok: 'OK',
  cancel: 'Cancel',
  state: 'State',
  zipCode: 'Zip code',
  taxId: 'Tax ID',
  taxIdType: 'Tax ID Type',
  organizationName: 'Organization name',
  entity: 'Entity',
  npi: 'Npi',
  taxonomyCode: 'Taxonomy code',
  admin: 'Admin',
  id: 'ID',
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  enterHere: 'Enter here',
  specializationPlaceholder: 'Start writing here (autocomplete)',
  insuranceInfo: 'Insurance info',
  descriptionExamplePlaceholder: 'Example: Nutritionist, Tv chef, Author',
  nutritionistSummaryEdit: 'Nutritionist summary edit',
  addFile: 'Add file',
  viewProfile: 'View Profile',
  filterYourPatientsBy: 'Filter your patients by',
  missing: '-',
  createYourAccount: 'Create your account',
  emailOrPhoneExists: 'The email or phone is already exists',
  userWithTheEmailAlreadyExists: 'User with the email already exists.',
  userWithThePhoneAlreadyExists: 'User with the phone already exists.',
  createMyAccount: 'Create my account',
  verification: 'Verification',
  email: 'Email',
  noCameraOrMicrophoneAvailable: "No camera or microphone is available. Maybe it's used by Zoom or anther software.",
  stayInSignUp: 'Stay in sign up',
  redirectToLogin: 'Redirect to login',
  alreadyHaveAnAccount: 'Already have and account?',
  weCantFindYourAccount: "We can't find your account",
  enterPhoneAndEmailToResetYourPassword: 'Enter phone and email to reset your password',
  resetYourPassword: 'Reset your password',
  enterTheCodeWeSentYouInPasswordToken: 'Enter the code we sent you in password token.',
  yourResetPasswordTokenIsIncorrect: 'Your reset password token is incorrect',
  pleaseVerifyYourPhoneNumber: 'Please verify your phone number',
  readTheTerms: 'Please read the terms',
  hello: 'Hello',
  next: 'Next',
  youAreConnectedToCalender: 'You are connected to your calender',
  iAgreeToThe: 'I agree to the',
  termsAndConditions: 'Terms and Conditions',
  doNotHaveAnAccount: "Don't have an account?",
  forgotYourPassword: 'Forgot your password?',
  reset: 'Reset',
  yourPasswordHasBeenChanged: 'Your password has been changed',
  endOfAppointment: 'End of appointment',
  cptCode: 'CPT code',
  diagnosisCode: 'Diagnosis Code',
  appointmentDurationInMinutes: 'Appointment duration',
  howLongWasTheAppointment: 'How long was the appointment',
  cxCode: 'CX code',
  dxCode: 'DX code',
  facilityName: 'Facility name',
  ptan: 'Ptan',
  ifApplicable: 'if applicable',
  medicaidId: 'Medicaid id',
  facilityNameIsRequired: 'Facility name is required',
  insuranceBilled: 'Insurance billed',
  cannotBillDueToAppointmentNotExist: 'Cannot bill due to appointment not exist',
  noAppointmentsWereNotBilled: 'There are no appointments that were not billed',
  duration: 'Duration',
  durationInMinutes: 'Duration in minutes',
  bill: 'Bill',
  expired: 'EXPIRED',
  startSession: 'START SESSION',
  terms: 'Terms',
  newPassword: 'New Password',
  passwordToken: 'Password Token',
  signup: 'Sign Up',
  pleaseReadThe: 'Please read the',
  ToContinue: 'to continue',
  nextAppointment: 'Next appointment',
  goBackToPatientsList: 'Go back to patients list',
  chatArea: 'Chat Area',
  patientId: 'Patient Id',
  age: 'Age',
  gender: 'Gender',
  weight: 'Weight',
  height: 'Height',
  bloodGroup: 'Blood group',
  allBloodTests: 'All blood tests',
  historyOfSpecificBloodTest: 'History Of specific blood test',
  bloodTest: 'Blood test',
  lastCallSummary: 'Last call summary',
  previousSummaries: 'Previous Summaries',
  generalAndDailyAnswers: 'General and daily answers',
  textAreaWordCounter: 'Word counter 30 from 100',
  save: 'Save',
  tellUsAboutYourself: 'Tell us about yourself',
  professionalInformation: 'professional information',
  informationSaved: 'Information Saved',
  headline: 'headline',
  dietitianType: 'Dietitian Type',
  textIsToShort: 'Text is to short',
  textIsToLong: 'Text is to Long',
  aboutMe: 'About me',
  about: 'About',
  myInsuranceInfo: 'My Insurance info',
  setDateAndTimeOfWork: 'Set date and time Of work',
  groups: 'Groups',
  add: 'Add',
  permissions: 'Permissions',
  group: 'Group',
  dietQuestions: 'Diet Questions',
  appointments: 'Appointments',
  logout: 'Logout',
  yesLogout: 'Yes, logout',
  imStaying: "I'm staying",
  typeYourMessage: 'Type your message',
  send: 'Send',
  appointmentNotExists: 'Appointment not exists',
  foods: 'foods',
  weHaveSentYouNewCode: 'We have sent you a new code',
  notReceivedSms: 'SMS Not received?',
  toSendAgain: 'To send again',
  clickHere: 'Click here',
  smsSendingFailed: 'Sms sending failed',
  signInCalendarFailed: 'Sign in to your calendar has been failed',
  phoneOrEmailNotValid: 'Phone or Email not valid',
  phoneOrEmail: 'Phone or Email',
  generalInformation: 'General Information',
  symptoms: 'Symptoms',
  diets: 'Diets',
  dietUserTried: 'Diet the user Tried',
  currentDiet: 'Current Diet',
  overallImprovementInTheseDiets: 'Overall improvement in these diets',
  newlyDiagnosed: 'Newly diagnosed',
  pastMedicationSupplements: 'Past medication supplements',
  significantMedical: 'Significant medical',
  surgicalHistory: 'Surgical history',
  skinConditions: 'Skin conditions',
  sleepingTime: 'Sleeping time',
  overallStress: 'Overall Stress',
  diarrhea: 'Diarrhea',
  mucusInStool: 'Mucus in stool',
  bloodyStool: 'Bloody stool',
  pain: 'Pain',
  postmealGassinessAndBloating: 'Postmeal gassiness and bloating',
  constipation: 'Constipation',
  undigestedFoodInStool: 'Undigested food in stool',
  bubblyFoamInStool: 'Bubbly foam in stool',
  greenStool: 'Green stool',
  blackStool: 'Black stool',
  nausea: 'Nausea',
  dailyQuestionsTrendsFoodLog: 'Daily Questions Trends and Food Log',
  reflux: 'Reflux',
  cannotAddPermissionUserNotExists: 'Cannot add permission, user not exists',
  actionDeniedDue: 'Action denied due',
  noAppointmentsFoundBetweenYouAndYourPatient: 'no appointments found between you and your patient',
  noAppointmentsFoundBetweenYouAndYourPatients: 'no appointments found between you and your patients',
  userNotExists: 'User not exists',
  DoNotHavePermissionAccessAddress: "You don't have permission to access this address",
};

export default texts;
