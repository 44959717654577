import { persistReducer, createTransform } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import dayjs from 'dayjs';

import reducers from './reducers';
import { SystemState } from '../types/system_state';
import { serializeUser, mapUser as deserializeUser } from '../utils/user';
import { serializePatient, mapPatient as deserializePatient } from '../utils/patients';

const whitelist: Array<string> = [
  'user',
  'dailyQuestionnaireSelectedTab',
  'selectedMicrophone',
  'selectedCamera',
  'availableDevices',
  'refreshToken',
  'accessTokenExpiredAt',
  'accessToken',
  'chatSelectedUser',
  'isVideoCallJoined',
  'currentAppointmentId',
  'isVideoCallActive',
  'appointmentShareVideo',
  'appointmentShareAudio',
  'schedulingAppointmentPickASlotTab'
];

const getLastCookieValue = (key: string) => {
  return document.cookie
    .split(';')
    ?.filter?.((c) => c.includes(`${key}=`))
    .pop()
    ?.split?.(`${key}=`)
    .pop();
};

const sessionStorage = window.sessionStorage;

const inboundTransformer = (inboundState: any, key: string | number): any => {
  switch (key) {
    case 'user':
      return serializeUser(inboundState);
    case 'accessTokenExpiredAt':
      return inboundState?.toISOString?.();
    case 'isVideoCallJoined':
      sessionStorage.setItem('isVideoCallJoined', inboundState);
      return inboundState;
    case 'currentAppointmentId':
      sessionStorage.setItem('currentAppointmentId', inboundState);
      return inboundState;
    case 'chatSelectedUser':
      return serializePatient(inboundState);
    case 'accessToken':
      document.cookie = `accessToken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      const expires = dayjs().add(1, 'day').toDate().toUTCString();
      document.cookie = `accessToken=${inboundState || ''};expires=${expires};path=/`;
      return inboundState;
    default:
      return inboundState;
  }
};

const outboundTransformer = (outboundState: any, key: string | number) => {
  switch (key) {
    case 'user':
      return deserializeUser(outboundState);
    case 'accessTokenExpiredAt':
      return outboundState ? dayjs(outboundState) : null;
    case 'isVideoCallJoined':
      const isVideoCallJoined = sessionStorage.getItem('isVideoCallJoined');
      return isVideoCallJoined === 'true';
    case 'currentAppointmentId':
      const currentAppointmentId = sessionStorage.getItem('currentAppointmentId');
      return currentAppointmentId ? Number(currentAppointmentId) : null;
    case 'chatSelectedUser':
      return outboundState ? deserializePatient(outboundState) : null;
    case 'accessToken':
      const accessToken = getLastCookieValue('accessToken');
      return accessToken;
    default:
      return outboundState;
  }
};

const transformConfig = { whitelist };
const setTransform: any = createTransform(inboundTransformer, outboundTransformer, transformConfig);

const persistConfig: PersistConfig<SystemState> = { key: 'root', storage, whitelist, transforms: [setTransform] };
export const persistedReducer = persistReducer(persistConfig, reducers);
