import { useState, useEffect } from 'react';

declare const InstallTrigger: any;
declare const navigator: any;

const getCanQuery = () => {
  const iosNames = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
  const isIos = iosNames.includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const canQuery = navigator.permissions?.query && !isFirefox && !isIos;
  return canQuery;
};

const usePermission = (name: any) => {
  const canQuery = getCanQuery();
  const [state, setState] = useState(canQuery ? null : 'unknown');

  useEffect(() => {
    let permissionStatus: any = null;
    const changeHandler = () => {
      setState(permissionStatus.state);
    };
    const query = async () => {
      permissionStatus = await navigator.permissions.query({ name });
      setState(permissionStatus.state);
      permissionStatus.addEventListener('change', changeHandler);
    };
    if (canQuery) {
      query();
    }
    return () => permissionStatus?.removeEventListener?.('change', changeHandler);
  }, [canQuery, name, setState]);

  return state;
};

export default usePermission;
