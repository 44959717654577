import { captureException } from '@sentry/browser';

import { snackbarRef } from './snackbar';
import { ErrorHandler, ActionErrorHandler } from '../types/errors';
import texts from './texts';

export const generalOnError = (error: ErrorHandler, actionErrorHandler?: ActionErrorHandler): void => {
  console.log(error);
  if (error.isTreated) {
    return;
  }
  const isTreated: boolean | undefined = actionErrorHandler?.(error);
  if (isTreated) {
    return;
  }
  snackbarRef?.current?.enqueueSnackbar(texts.generalError, { variant: 'error', autoHideDuration: 5000 });
  captureException(error);
};
