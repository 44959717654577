import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Dictionary } from 'lodash';

interface Props {
  key: string;
}

export const useReduxState = ({ key }: Props) => {
  const dispatch = useDispatch();
  const value = useSelector((s: Dictionary<any>) => s[key]);
  const setValue = useCallback((newValue) => dispatch({ type: key, payload: newValue }), [dispatch, key]);
  return [value, setValue];
};
