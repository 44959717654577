import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { SystemState } from '../types/system_state';
import { generalOnError } from '../utils/errors';

export const refreshAction = (reduxKey: string, version?: number): ThunkAction<void, SystemState, unknown, Action<string>> => async (
  dispatch,
  getState,
) => {
  const { accessToken } = getState();
  if (accessToken) {
    try {
      const getLink = `/${reduxKey}/${version ? `v${version}/` : ''}`
      const { data } = await axios.get(getLink);
      dispatch({ type: reduxKey, payload: data });
    } catch (err: any) {
      generalOnError(err);
    }
  }
};
