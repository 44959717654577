import { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import Icon from './svg_icon';
import EndVideoCallDialog from './end_video_call_dialog';
import texts from '../utils/texts';

const useClasses = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
    minWidth: 44,
    height: 44,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgb(255, 0, 0, 0.8)',
    },
  },
});

const EndCallButton = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const classes = useClasses();

  const toggleIsConfirmOpen = () => setIsConfirmOpen((v) => !v);

  return (
    <>
      <Tooltip title={texts.endCall} placement="top">
        <Button className={classes.container} onClick={toggleIsConfirmOpen}>
          <Icon name="close" fill="white" width={32} height={32} />
        </Button>
      </Tooltip>
      <EndVideoCallDialog isConfirmOpen={isConfirmOpen} setIsConfirmOpen={setIsConfirmOpen} />
    </>
  );
};

export default EndCallButton;
