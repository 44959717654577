import { ComponentType } from 'react';
import promise from 'redux-promise';
import freeze from 'redux-freeze';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import isEmpty from 'lodash/isEmpty';

import { persistedReducer } from './persist';
import Loading from '../organism/loading';

const env = runtimeEnv();
window.env = isEmpty(env) ? process.env : env;

const reduxMiddlewares = [thunk, promise];
if (window.env.REACT_APP_DEBUG === 'true') {
  reduxMiddlewares.push(freeze);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...reduxMiddlewares)));

export const persistor = persistStore(store);

export const withReduxProvider = (App: ComponentType) => {
  return (props: any) => {
    return (
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <App {...props} />
        </PersistGate>
      </Provider>
    );
  };
};
