import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  badge: {
    fontSize: 10,
    textTransform: 'uppercase',
  },
});

interface Props {
  className?: string;
  children: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}

const Badge: React.ComponentType<Props> = React.forwardRef(({ className, children }: Props, ref?: React.Ref<HTMLDivElement>) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={classNames(classes.badge, className)}>
      {children}
    </div>
  );
});

export default Badge;
