import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

import VideoPanelButton from './video_panel_button';
import EndCallButton from './end_call_button';
import VideoPanelSettings from './video_panel_settings';
import texts from '../utils/texts';

const useClasses = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'transparent',
    padding: 15,
    zIndex: 1,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
});

const VideoPanel = () => {
  const dispatch = useDispatch();
  const shareVideo = useSelector((s) => s.appointmentShareVideo);
  const shareAudio = useSelector((s) => s.appointmentShareAudio);
  const isVideoCallActive = useSelector((s) => s.isVideoCallActive);
  const videoSize = useSelector((s) => s.videoSize);
  const isMaximize = videoSize === 'maximize';
  const classes = useClasses();

  const toggleShareVideo = () => {
    dispatch({ type: 'appointmentShareVideo', payload: !shareVideo });
  };
  const toggleShareAudio = () => {
    dispatch({ type: 'appointmentShareAudio', payload: !shareAudio });
  };
  const toggleVideoCall = () => {
    dispatch({ type: 'isVideoCallActive', payload: !isVideoCallActive });
  };

  const videoText = shareVideo ? texts.turnOffCamera : texts.turnOnCamera;
  const audioText = shareAudio ? texts.mute : texts.unmute;
  const videoCallText = isVideoCallActive ? texts.pauseCall : texts.resumeCall;
  const muteIcon = shareAudio ? 'mute' : 'unmute';
  const videoIcon = shareVideo ? 'video_on' : 'video_off';
  const videoCallIcon = isVideoCallActive ? 'pause' : 'play';
  const defaultIconSize = isMaximize ? 22 : 18;
  const videoCallIconSize = isMaximize ? 15 : 11;
  return (
    <div className={classes.container}>
      <VideoPanelSettings />
      <div className={classes.actions}>
        <VideoPanelButton
          iconName={muteIcon}
          onClick={toggleShareAudio}
          isEnabled={shareAudio}
          toolTipText={audioText}
          iconSize={defaultIconSize}
        />
        <VideoPanelButton
          iconName={videoIcon}
          onClick={toggleShareVideo}
          isEnabled={shareVideo}
          toolTipText={videoText}
          iconSize={defaultIconSize}
        />
        <VideoPanelButton
          iconName={videoCallIcon}
          onClick={toggleVideoCall}
          isEnabled={isVideoCallActive}
          iconSize={videoCallIconSize}
          toolTipText={videoCallText}
        />
        <EndCallButton />
      </div>
    </div>
  );
};

export default VideoPanel;
