import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    zIndex: 50,
  },
});

interface Props {
  inAction?: boolean;
  className?: string;
}

const Loading: React.FC<Props> = ({ inAction = true, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, className)}>
      <Fade in={inAction} unmountOnExit>
        <CircularProgress />
      </Fade>
    </div>
  );
};

export default Loading;
