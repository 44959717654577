import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation } from 'react-router-dom';

import SidebarButton from './sidebar_button';
import Logout from '../molecules/logout';
import { useReduxState } from '../hooks/use_redux_state';
import { TAMAR_PATIENTS_ADMINS } from '../utils/admins_phones';
import { routeHasBar } from '../components/router';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '50px 27px',
    flexShrink: 0,
    backgroundColor: 'white',
    boxShadow: '0 1px 10px rgba(0,0,0,0.2)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
    fontSize: 14,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logoutButton: {
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    marginBottom: 35,
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logoutText: {
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
}));

const AppSidebar = () => {
  const classes = useStyles();
  const location = useLocation();
  const [user] = useReduxState({ key: 'user' });
  const isProviderApprover = user?.groups?.providersApprover;
  const isAdmin = user?.groups?.admin;
  const hasUnbilledAppointments = user?.unBilled.length > 0;
  const isTamarPatientAdmin = TAMAR_PATIENTS_ADMINS.includes(user?.phone);

  if (!routeHasBar(location)) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div>
        <SidebarButton route="dashboard" text="Dashboard" />
        <SidebarButton route="calendar" text="Calendar" />
        <SidebarButton route="appointments" text="Appointments" />
        <SidebarButton route="patients" text="Patients" />
        <SidebarButton route="profile" text="Profile" />
        <SidebarButton route="library" text="Library" />
        {hasUnbilledAppointments && <SidebarButton route="unbilled-appointments" text="Un Billed" />}
        {isProviderApprover && <SidebarButton route="interviews" text="Interviews" />}
        {isAdmin && <SidebarButton route="insurance-patient-approval" text="Insurance Patients" />}
        {isAdmin && <SidebarButton route="insurance-claim-approval" text="Insurance Claims" />}
        {isTamarPatientAdmin && <SidebarButton route="tamar-patients" text="Tamar Patients" />}
        <SidebarButton route="reports" text="Reports" />
        {isAdmin && <SidebarButton route="admin-reports" text="Admin Reports" />}
      </div>
      <Logout className={classes.logoutButton} textClassName={classes.logoutText} variant="text" />
    </div>
  );
};

export default AppSidebar;
