import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';

import { useReduxState } from '../hooks/use_redux_state';
import texts from '../utils/texts';

const useClasses = makeStyles({
  link: {
    position: 'fixed',
    zIndex: 1,
    alignSelf: 'center',
    textDecoration: 'none',
  },
  text: {
    padding: '3px 15px',
    color: 'rgba(0,0,0, 0.5)',
    backgroundColor: 'rgba(225,203,5, 0.6)',
    '&:hover': {
      background: 'rgba(225,203,5, 0.7)',
    },
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 500,
  },
});

const BackToAppointmentLink = ({ setVideoYPosition, setVideoXPosition }) => {
  const [currentAppointmentId] = useReduxState({ key: 'currentAppointmentId' });
  const [, setVideoScroll] = useReduxState({ key: 'videoScroll' });
  const classes = useClasses();

  const resetVideoPosition = () => {
    setVideoYPosition(0);
    setVideoXPosition(0);
    setVideoScroll(0);
  };

  const to = `/appointments/${currentAppointmentId}`;
  return (
    <Link className={classes.link} to={to} href={to} onClick={resetVideoPosition}>
      <div className={classes.text}>{texts.backToAppointment}</div>
    </Link>
  );
};

export default BackToAppointmentLink;
