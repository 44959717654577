import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import texts from '../utils/texts';
import { logout } from '../actions/auth';
import LogoutDialog from './logout_dialog';
import Button from '../gi_styles/button';

interface Props {
  className?: string;
  textClassName?: string;
  variant?: 'text' | 'outlined' | 'contained';
}

const Logout: React.FC<Props> = ({ className, textClassName, variant = 'contained' }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const onCloseLogoutDialog = () => setIsOpenDialog(false);

  const onLogout = () => {
    onCloseLogoutDialog();
    dispatch(logout());
  };

  const openDialog = () => {
    setIsOpenDialog(true);
  };

  return (
    <>
      <Button
        data-testid="logout-button"
        className={className}
        textClassName={textClassName}
        onClick={openDialog}
        text={texts.logout}
        variant={variant}
        disableRipple
      />
      {isOpenDialog && <LogoutDialog isOpen={isOpenDialog} onClose={onCloseLogoutDialog} onLogout={onLogout} />}
    </>
  );
};

export default Logout;
