import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OTPublisher } from 'opentok-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Draggable from 'react-draggable';

import useMediaSourceOptions from '../hooks/use_media_source_options';
import usePermission from '../hooks/use_permission';
import texts from '../utils/texts';
import { isSameDevice } from '../utils/devices';
import { store } from '../redux/store';
import { useReduxState } from '../hooks/use_redux_state';

const useClasses = makeStyles({
  container: {
    flex: 1,
    height: '85%',
    width: '100%',
    padding: 10,
    position: 'absolute',
  },
  publisher: ({ width, height, videoSize }) => ({
    width,
    height,
    position: 'absolute',
    top: videoSize === 'maximize' ? 10 : 2000,
    right: 10,
    zIndex: 10,
    opacity: 0.8,
    borderRadius: 5,
    overflow: 'hidden',
  }),
});

const calculateDimensions = (cameraDimensions) => {
  const ratio = cameraDimensions ? cameraDimensions.height / cameraDimensions.width : 0.75;
  const width = 100;
  const height = width * ratio;
  return { width, height };
};

const VideoPublisher = () => {
  const cameraPermission = usePermission('camera');
  const microphonePermission = usePermission('microphone');
  const selectedMicrophone = useSelector((s) => s.selectedMicrophone);
  const selectedCamera = useSelector((s) => s.selectedCamera);
  const isVideoCallJoined = useSelector((s) => s.isVideoCallJoined);
  const shareVideo = useSelector((s) => s.appointmentShareVideo);
  const isVideoCallActive = useSelector((s) => s.isVideoCallActive);
  const shareAudio = useSelector((s) => s.appointmentShareAudio);
  const videoSize = useSelector((s) => s.videoSize);
  const availableDevices = useSelector((s) => s.availableDevices);
  const [cameraDimensions, setCameraDimensions] = useState(null);
  const [, setPublisherHasVideoError] = useReduxState({ key: 'publisherHasVideoError' });
  const publisherRef = useRef(null);
  const selectedMicrophoneDeviceIdRef = useRef(null);
  const availableDevicesRef = useRef(availableDevices);
  const { width, height } = calculateDimensions(cameraDimensions);
  const classes = useClasses({ width, height, videoSize });
  const dispatch = useDispatch();

  useMediaSourceOptions({ intervalDelay: 1000 * 1 });

  document.querySelectorAll('.OT_edge-bar-item').forEach((e) => {
    e.style.display = 'none';
  });
  document.querySelectorAll('.OT_mode-auto').forEach((e) => {
    e.style.display = 'none';
  });

  useEffect(() => {
    document.querySelectorAll('.OTPublisherContainer').forEach((publisher) => {
      publisher.style.width = `${width}px`;
      publisher.style.height = `${height}px`;
    });
  }, [width, height]);

  useEffect(() => {
    const getNewDevice = (kind, newDevices) => {
      const oldDevices = availableDevicesRef.current?.filter((d) => d.kind === kind);
      const hasPluggedNewDevice = oldDevices?.length < newDevices?.length;
      if (hasPluggedNewDevice) {
        const newDevice = newDevices.find((nd) => !oldDevices.some((od) => od.deviceId === nd.deviceId));
        return newDevice;
      }
      return null;
    };

    const updateDevice = (selectedDeviceReduxKey, kind) => {
      const selectedDevice = store.getState()[selectedDeviceReduxKey];
      const isSelectedDeviceExist = availableDevices?.some((d) => isSameDevice(d, selectedDevice));
      const devices = availableDevices?.filter((d) => d.kind === kind);
      const newDevice = getNewDevice(kind, devices);
      if (newDevice) {
        dispatch({ type: selectedDeviceReduxKey, payload: newDevice });
      } else if (!isSelectedDeviceExist) {
        dispatch({ type: selectedDeviceReduxKey, payload: devices[0] || null });
      }
    };

    const hasPermission = Boolean(availableDevices?.[0]?.deviceId);
    if (hasPermission) {
      updateDevice('selectedCamera', 'videoinput');
      updateDevice('selectedMicrophone', 'audioinput');
      availableDevicesRef.current = availableDevices;
    }
  }, [dispatch, availableDevices]);

  const buildPublisherProperties = () => {
    const shouldPublishAudio = isVideoCallJoined && isVideoCallActive && shareAudio;
    const shouldPublishVideo = isVideoCallJoined && isVideoCallActive && shareVideo;
    const properties = {
      audioBitrate: 64000,
      width,
      height,
      publishAudio: shouldPublishAudio,
      publishVideo: shouldPublishVideo,
      showControls: false,
    };
    properties.videoSource = selectedCamera;
    const microphone = shouldPublishAudio ? selectedMicrophone : null;
    const publisher = publisherRef.current?.getPublisher();
    if (publisher && microphone && selectedMicrophoneDeviceIdRef.current !== microphone.deviceId) {
      publisher.setAudioSource(microphone.deviceId);
      selectedMicrophoneDeviceIdRef.current = microphone.deviceId;
    }
    return properties;
  };

  const hasPermission =
    ['unknown', 'granted'].includes(cameraPermission) && ['unknown', 'granted'].includes(microphonePermission);

  const streamCreated = useCallback(
    ({ stream }) => {
      const hasError = shareVideo && isVideoCallActive && !stream.videoType;
      setPublisherHasVideoError(hasError);

      if (stream.videoDimensions.width > 0) {
        setCameraDimensions(stream.videoDimensions);
      }
    },
    [setPublisherHasVideoError, shareVideo, isVideoCallActive],
  );

  return (
    <div className={classes.container}>
      <Draggable
        bounds="parent"
        defaultPosition={{ x: 0, y: 0 }}
        onStart={(e) => {
          e.stopPropagation();
        }}>
        <div className={classes.publisher}>
          {hasPermission && (
            <OTPublisher ref={publisherRef} eventHandlers={{ streamCreated }} properties={buildPublisherProperties()} />
          )}
          {!hasPermission && <div>{texts.approveUserMedia}</div>}
        </div>
      </Draggable>
    </div>
  );
};

export default VideoPublisher;
