import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import texts from '../utils/texts';
import Button from '../gi_styles/button';
import Transition from './transition';

const LogoutDialog = ({ isOpen, onClose, onLogout }) => {
  return (
    <Dialog open={isOpen} TransitionComponent={Transition} keepMounted onClose={onClose} aria-labelledby="logout-dialog-title">
      <DialogTitle id="logout-dialog-title">{`${texts.logout}?`}</DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} text={texts.imStaying} />
        <Button
          data-testid="confirm-logout-button"
          color="secondary"
          variant="outlined"
          onClick={onLogout}
          text={texts.yesLogout}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
